import React from "react";
import { CompositionForm } from "../../components/CompositionForm";
import { Spinner } from "../../components/Spinner";
import { useAppSelector, useComposition } from "../../hooks";

export const CompositionCreate = () => {
    const { loading } = useAppSelector(state => state.compositions);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [composition, _, disabled, validationResult, onChange, onCheckedChange, onAddImage, onRemoveImage, onValidate, onSubmit, onFake] = useComposition();

    return(loading ? <Spinner /> : 
        <CompositionForm 
            composition={composition} 
            disabled={disabled} 
            validationResult={validationResult} 
            onChange={onChange}
            onCheckedChange={onCheckedChange}
            onAddImage={onAddImage}
            onRemoveImage={onRemoveImage}
            onValidate={onValidate}
            onSubmit={onSubmit}
            onFake={onFake} />);
}