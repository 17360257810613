import { Guest } from "../types/Guest"

const guest1: Guest = {
    id: undefined,
    first: 'Jennie',
    last: 'Nichols',
    email: 'jennie.nichols@example.com',
    phone: '(489) 330-2385',
    address1: '8929 Valwood Pkwy',
    address2: '',
    city: 'Billings',
    state: 'Michigan',
    zip: '63104',
    subscribed: false
}

const guest2: Guest = {
    id: undefined,
    first: 'Rhonda',
    last: 'Hawkins',
    email: 'rhonda.hawkins@example.com',
    phone: '(846) 607-6145',
    address1: '7092 Camden Ave',
    address2: 'Apt 1A',
    city: 'Easton',
    state: 'MD',
    zip: '21601',
    subscribed: false
}

const guest3: Guest = {
    id: undefined,
    first: 'Samuel',
    last: 'Curtis',
    email: 'samuel.curtis@example.com',
    phone: '876-659-5822',
    address1: '435 Thornridge Cir',
    address2: 'Unit 11',
    city: 'Rochester Hills',
    state: 'MI',
    zip: '48307',
    subscribed: false
}

const guest4: Guest = {
    id: undefined,
    first: 'Dwight',
    last: 'Willis',
    email: 'dwight.willis@example.com',
    phone: '(935) 675-1961',
    address1: '5889 Paddock Way',
    address2: '#2',
    city: 'Canandaigua',
    state: 'NY',
    zip: '14424',
    subscribed: false
}

export const testGuests = () => [guest1, guest2, guest3, guest4];
export const randomTestGuest = () => {
    const guests = testGuests();
    return guests[Math.floor(Math.random() * guests.length)];
}