import React from "react";
import { Client } from "../types/Client";
import { ValidationResult } from "../types/ValidationResult";
import { useCollapse, useComposition, useUser } from "../hooks";
import { User } from "../types/User";
import { Composition } from "../types/Composition";
import { Link } from "react-router-dom";
import { formatCurrency } from "../utils/formatUtils";
import { CompositionForm } from "./CompositionForm";
import { Spinner } from "./Spinner";
import { UserForm } from "./UserForm";

interface ClientFormProps {
    client: Client;
    disabled: boolean;
    validationResult: ValidationResult<Client>;
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onAddUser: (user: User) => void;
    onAddComposition: (composition: Composition) => void;
    onValidate: (client?: Client) => boolean;
    onSubmit: (client: Client) => Promise<void>;
}

export const ClientForm = ({client, disabled, validationResult, onChange, onAddUser, onAddComposition, onValidate, onSubmit}: ClientFormProps) => {
    const {id, name, brandName, users, compositions, isActive} = client;
    const [userFormRef, onToggleUserForm] = useCollapse();
    const [compositionFormRef, onToggleCompositionForm] = useCollapse();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [composition, compositionLoading, compositionDisabled, compositionValidationResult, onCompositionChange, onCompositionCheckedChange, onAddImage, onRemoveImage, onCompositionValidate, onCompositionSubmit, onCompositionFake] = useComposition();
    const [user, userLoading, userDisabled, userValidationResult, onUserChange, onUserValidate, onUserSubmit, onUserFake] = useUser();

    const handleSubmit = React.useCallback((client?: Client) => {
        const valid = onValidate(client); // need to know if valid immediately
        
        if (valid) {
            onSubmit(client!);
        }
    }, [onSubmit, onValidate]);

    const handleAddUser = React.useCallback(async (user: User & {password: string}) => {
        const valid = onUserValidate(user);
        if (valid) {
            await onUserSubmit(user);
            onAddUser(user);
        }
    }, [onUserValidate, onUserSubmit, onAddUser]);

    const handleAddComposition = React.useCallback(async (composition: Composition) => {
        const valid = onCompositionValidate(composition);
        if (valid) {
            await onCompositionSubmit(composition);
            onAddComposition(composition);
        }
    }, [onCompositionValidate, onCompositionSubmit, onAddComposition]);

    return(compositionLoading || userLoading ? <Spinner /> :
        <div className="d-flex flex-column">
            {/* Client Details */}
            <div className="d-flex flex-column shadow-sm m-3 p-3 mb-1 rounded bg-white">
                <strong className="my-2">Client Details</strong>
                <div className="d-flex align-items-end mb-3">
                    <div className="flex-fill me-3">
                        <small className="form-label">Brand</small>
                        <input type="text" name="brandName" className={`form-control form-control-sm ${validationResult.errors.brandName ? 'is-invalid' : null}`} value={brandName} onChange={onChange} />
                        <small className="text-danger">{validationResult.errors.brandName}</small>
                    </div>
                    <div className="flex-fill">
                        <small className="form-label">Name</small>
                        <input type="text" name="name" className={`form-control form-control-sm ${validationResult.errors.name ? 'is-invalid' : null}`} value={name} onChange={onChange} />
                        <small className="text-danger">{validationResult.errors.name}</small>
                    </div>
                    <div className="form-check form-switch mx-3">
                        <input className="form-check-input" type="checkbox" role="switch" name='isActive' checked={isActive} onChange={onChange} />
                        <label className="form-check-label">Active</label>
                    </div>
                    <button className="btn btn-primary ms-auto ms-3" disabled={disabled} onClick={() => handleSubmit(client)}>Save</button>
                </div>
            </div>
            {/* Client Users */}
            <div className="d-flex flex-column shadow-sm m-3 p-3 mb-1 rounded bg-white">
                <div className="d-flex align-items-center my-2">
                    <strong className="my-2">Client Users</strong>
                    <button className="btn btn-primary btn-sm ms-auto" disabled={!id || !isActive} onClick={onToggleUserForm}><i className="bi bi-plus" /></button>
                </div>
                <div className="d-flex flex-column">
                    <table>
                        <tbody>
                            {users?.length > 0 && users.map((u, i) => 
                                <tr key={`${i}-${u.id}`}>
                                    <td><Link to={`/admin/users/${u.id}`}>{u.first} {u.last}</Link></td>
                                    <td>{u.email}</td>
                                    <td>{u.role}</td>
                                    <td>{u.isActive ? 'Active' : 'Inactive'}</td>
                                </tr>)}
                        </tbody>
                    </table>
                    <div className="collapse card card-body mt-3" ref={userFormRef}>
                        <UserForm 
                            user={user} 
                            disabled={userDisabled} 
                            validationResult={userValidationResult} 
                            onChange={onUserChange} 
                            onValidate={onUserValidate} 
                            onSubmit={handleAddUser} 
                            onFake={onUserFake} />
                    </div>
                </div>
            </div>
            {/* Client Compositions */}
            <div className="d-flex flex-column shadow-sm m-3 p-3 mb-1 rounded bg-white">
                <div className="d-flex align-items-center my-2">
                    <strong className="my-2">Client Compositions</strong>
                    <button className="btn btn-primary btn-sm ms-auto" disabled={!id || !isActive} onClick={onToggleCompositionForm}><i className="bi bi-plus" /></button>
                </div>
                <div className="d-flex flex-column">
                    <div className="collapse card card-body mb-3" ref={compositionFormRef}>
                        <CompositionForm
                            composition={composition} 
                            disabled={compositionDisabled} 
                            validationResult={compositionValidationResult} 
                            onChange={onCompositionChange} 
                            onCheckedChange={onCompositionCheckedChange} 
                            onAddImage={onAddImage}
                            onRemoveImage={onRemoveImage}
                            onValidate={onCompositionValidate}
                            onSubmit={handleAddComposition}
                            onFake={onCompositionFake} />
                    </div>
                    <table>
                        <tbody>
                            {compositions?.length > 0 && compositions.map((c, i) => 
                                <tr key={`${i}-${c.id}`}>
                                    {c.images && c.images.length > 0 && <td><img width={50} src={c.images[0].src} alt={c.images[0].alt} className="img-fluid hover-zoom" /></td>}
                                    <td><Link to={`/admin/compositions/${c.id}`}>{c.title}</Link></td>
                                    {c.price && <td className="text-end"><span>{formatCurrency(c.price)}</span></td>}
                                </tr>)}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>);
}