import React from "react";

export const About = () => {
    const src = 'https://cdn.shopify.com/s/files/1/0941/7736/files/LALifestylePhotographyforAlyssaBreid-51.jpg?v=1642705462'
    // const initState = {email: '', body: ''} as Message;
    // const [message, setMessage] = React.useState(initState);
    // const [validationResult, setValidationResult] = React.useState<ValidationResult<Message>>({valid: false, errors: {id: '', email: '', body: ''}});

    // const handleChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    //     e.preventDefault();
    //     const { name, value } = e.target;
    //     const error = validateMessageField({name, value});
    //     setValidationResult((prev: ValidationResult<Message>) => ({...prev, errors: {...prev.errors, [name]: error}}));
    //     setMessage(prev => ({...prev, ...{[name]: value}}));
    // }, []);

    // const handleSubmit = async () => {
    //     const {valid, errors} = validateMessage(message);
    //     setValidationResult({valid, errors});
    //     if (valid) {
    //         const result = await guestService.send(message);
    //         if (result.id) {
    //             toast.success(`Message sent`);
    //             setMessage(initState);
    //         }
    //     }      
    // }

    return(
        <div className="d-flex flex-column align-items-center justify-content-center w-100">
            <div className="d-flex flex-column flex-md-row mx-auto">
                <img src={src} className="img-fluid m-3" alt="Alyssa Breid" style={{maxHeight: 500}} />
                <div className="d-flex flex-column m-3" style={{maxWidth: 333}}>
                    <p>A classically-trained flutist, Alyssa Breid found her artistic medium in minimalist weaving on canvas—a practice that is influenced by the Japanese and Scandinavian concepts of wabi-sabi and hygge—that are rich in detail and spontaneous in form. Breid’s work has been featured in the Netherland’s design magazine VTWonen and is featured in numerous international interior designs.</p>
                    <p className="lead text-end mt-auto">“I feel that I am a part of a tradition that generations before me have done, working with thread, working with their hands. It is my goal to carry on this tradition, but in a new way.”</p>
                </div>
            </div>
            {/* <div className="mb-3 w-50">
                <label>Email</label>
                <input type="text" className={`form-control stripe-input ${validationResult.errors.email ? 'is-invalid' : null}`} value={message.email} name="email" onChange={handleChange} />
                <small className="text-danger">{validationResult.errors.email}</small>
            </div>
            <div className="mb-3 w-50">
                <label>Message</label>
                <textarea className={`form-control stripe-input ${validationResult.errors.body ? 'is-invalid' : null}`} rows={3} value={message.body} name="body" onChange={handleChange} />
                <small className="text-danger">{validationResult.errors.body}</small>
            </div>
            <button type="button" className="btn btn-outline-dark rounded-0 py-2 px-3" onClick={handleSubmit}>SEND</button> */}
        </div>);
}