import { ValidationResult } from "./ValidationResult";

export interface Message {
    id: string | undefined;
    email: string;
    body: string;
}

export function validateMessage(message?: Partial<Message>) {
    const validationResult: ValidationResult<Message> = {
        valid: false,
        errors: {id: '', email: '', body: '', }
    };

    if (!message || Object.entries(message).length === 0) {
        for (const key in validationResult.errors) {
            (validationResult.errors as any)[key] = validateMessageField({key, value: undefined});
        }    
        return validationResult;
    }
  
    let key: keyof Message;
    for (key in message) {
        const value = message ? message[key] : undefined;
        validationResult.errors[key] = validateMessageField({key, value});
    }    
  
    // no errors, validate
    if (Object.entries(validationResult.errors).every(e => !e[1])) {
        validationResult.valid = true;
    }
  
    return validationResult; 
}

export function validateMessageField({key, value}: {[key: string]: string | undefined}): string {
    let error = '';

    switch (key) {
    case 'email':
        if (!value) {
            error = 'Email is required';
        }
        break;
    case 'body':
        if (!value) {
            error = 'Message is required';
        }
        break;
    case 'id':        
    default:
        break;
    }
    console.log('Message validation error', error);
    return error;
}
  