import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { login } from "../features/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../hooks";
import { Spinner } from "../components/Spinner";

export const Login: React.FC = () => {
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { user, loading, success, error } = useAppSelector((state) => state.auth);

    React.useEffect(() => {
        if (error) {
            toast.error(error as string);
        }

        if (success || user) {
            navigate(location.state?.from.pathname || '/');
        }    
    }, [user, success, error, navigate, location.state?.from.pathname]);

    const handleEmailChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setEmail(e.target.value);
    }, []);

    const handlePasswordChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setPassword(e.target.value);
    }, []);

    const handleLogin = React.useCallback(() => {
        dispatch(login({ email, password }));
    }, [dispatch, email, password]);

    return (
        loading ? <Spinner /> :
        <div className="d-flex flex-column align-items-center justify-content-center h-100">
            <div className="mb-3">
                <label>Email address</label>
                <input type="email" className="form-control stripe-input" placeholder="name@example.com" value={email} onChange={handleEmailChange} />
            </div>
            <div className="mb-3">
                <label>Password</label>
                <input type="password" className="form-control stripe-input" placeholder="Password" value={password} onChange={handlePasswordChange} />
            </div>
            <button type="submit" className="btn btn-primary" onClick={handleLogin}>Log In</button>
        </div>
    );
}
