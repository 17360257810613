import { capitalize } from "../utils/formatUtils";
import { BusinessEntity, validateBusinessEntity, initValidationResult as initBusinessEntityValidationResult } from "./BusinessEntity";
import { InvoiceValidationResult, ValidationResult } from "./ValidationResult";

export enum Status {
    Active = 'Active',
    Paid = 'Paid',
    PastDue = 'PastDue',
    Void = 'Void',
}

export interface LineItem {
    id: string | undefined;
    name: string;
    description: string | undefined;
    metadata: {};
    quantity: number;
    price: number;
    stripePriceId: string | undefined;
    sort: number;
}

export interface Invoice {
    id: string | undefined;
    number: string;
    date: Date;
    status: Status;
    to: BusinessEntity;
    from: BusinessEntity;
    lineItems: LineItem[];
    subtotal: number | undefined;
    tax: number | undefined;
    discount: number | undefined;
    total: number | undefined;
    balanceDue: number | undefined;
    dueDate: Date | null;
    notes: string | undefined;
    stripePaymentLink: string | undefined;
}

export const init: Invoice = {
    id: '',
    number: '',
    date: new Date(),
    to: {id: '', name: '', first: '', last: '', address1: '', address2: '', city: '', state: '', zip: '', phone: '', email: '', website: ''},
    from: {id: '', name: '', first: '', last: '', address1: '', address2: '', city: '', state: '', zip: '', phone: '', email: '', website: ''},
    lineItems: [{id: '', stripePriceId: '', metadata: {}, name: '', description: '', quantity: 1, price: 0, sort: 1}],
    status: Status.Active,
    subtotal: 0,
    discount: 0,
    tax: 0,
    total: 0,
    balanceDue: 0,
    dueDate: null,
    notes: '',
    stripePaymentLink: ''
}

export const calculate = (lineItems: LineItem[]) => {
    const subtotal = lineItems.reduce((acc, { price, quantity }) => (acc + (price * quantity)), 0);
    const tax = 0;
    const discount = 0;
    const total = subtotal + tax + discount; 
    return { subtotal, tax, discount, total };
}

export function validateInvoice(invoice?: Partial<Invoice>): InvoiceValidationResult {
    const validationResult = {
        valid: false,
        errors: {
            id: '', 
            number: '', 
            date: '', 
            to: initBusinessEntityValidationResult, 
            from:  initBusinessEntityValidationResult, 
            lineItems: [], 
            subtotal: '', 
            tax: '', 
            discount: '', 
            total: '',
            status: '',
            balanceDue: '',
            dueDate: '',
            notes: '',
            stripePaymentLink: '',
        }
    };

    if (!invoice || Object.entries(invoice).length === 0) {
        for (const key in validationResult.errors) {
            // itemize errors by key to know exactly what is missing
            (validationResult.errors as any)[key] = validateInvoiceField({key, value: undefined});
        }    
        return validationResult as InvoiceValidationResult;
    }

    let key: keyof Invoice;
    for (key in invoice) {
        const value = invoice ? invoice[key] : undefined;
        (validationResult.errors as any)[key] = validateInvoiceField({key, value});        
    }    

    // no errors, validate
    if (Object.entries(validationResult.errors).every(e => !e[1])) {
        validationResult.valid = true;
    }

    return validationResult as InvoiceValidationResult; 
}

export function validateInvoiceField({key, value}: {[key: string]: string | number | Date | BusinessEntity | LineItem[] | undefined | null}): string | ValidationResult<BusinessEntity> | ValidationResult<LineItem>[] | null {
    let error: string | ValidationResult<BusinessEntity> | ValidationResult<LineItem>[] | null = null;

    switch (key) {
        case 'to':
        case 'from':
            if (!value) {
                error = `'Bill ${capitalize(key)}' is required`;
            } else {
                const vr = validateBusinessEntity(value as BusinessEntity);
                if (!vr.valid) {
                    error = vr;
                }                
            }
            break;
        case 'lineItems':
            if (!value || (Array.isArray(value) && value.length === 0)) {
                error = 'At least one line item is required';
            } else {
                const livrs = (value as LineItem[]).map(li => {
                    const lineItemValidationResult = validateLineItem(li);
                    return {...lineItemValidationResult, sort: li.sort };
                });

                if (livrs.some(vr => !vr.valid)) {
                    error = livrs;
                }
            }
            break;
        case 'dueDate':
            const startOfDay = new Date(new Date().setHours(0,0,0,0)); // This will set the time to start of day today (00:00:00.000 of the current timezone)
            if (value && value < startOfDay) { // Is the selected due date before start of day today?
                error = 'Due date cannot be in the past';
            }
            break;
        case 'date':
        case 'subtotal':
        case 'tax':
        case 'total':
        case 'status':
        case 'balanceDue':            
        case 'stripePaymentLink':
        case 'discount':
        default:
            break;
    }

    if(error) console.log('Invoice validation error', error);
    return error;
}

export function validateLineItem(lineItem?: Partial<LineItem>) {
    const validationResult: ValidationResult<LineItem> = {
        valid: false,
        errors: {
            id: '',
            name: '',
            description: '',
            metadata: '',
            price: '',
            quantity: '',
            stripePriceId: '',
            sort: ''
        }
    };

    if (!lineItem || Object.entries(lineItem).length === 0) {
        for (const key in validationResult.errors) {
            // itemize errors by key to know exactly what is missing
            (validationResult.errors as any)[key] = validateLineItemField({key, value: undefined});
        }    
        return validationResult;
    }

    let key: keyof LineItem;
    for (key in lineItem) {
        const value = lineItem ? lineItem[key] : undefined;
        validationResult.errors[key] = validateLineItemField({key, value});
    }    

    // no errors, validate
    if (Object.entries(validationResult.errors).every(e => !e[1])) {
        validationResult.valid = true;
    }

    return validationResult; 
}

export function validateLineItemField({key, value}: {[key: string]: string| number | {} | undefined}): string {
    let error = '';

    switch (key) {
        case 'name':
            if (!value) {
                error = 'Name is required';
            }
            break;
        case 'quantity':
        case 'price':
            if (!value) {
                error = `${capitalize(key as string)} is required`;
            } else if ((value as number) <= 0) {
                error = `${capitalize(key as string)} must be a positive number`;
            }
            break;
        case 'description':
        case 'metadata':
        case 'url': 
        default:
            break;
    }
    if(error) console.log('Invoice line item validation error', error);
    return error;
}