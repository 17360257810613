import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Composition } from "../../types/Composition";
import { compositionService } from "./compositionService";
import { toast } from "react-toastify";
import { narrowError } from "../../utils/errorUtils";
import { BaseState } from "../shared/BaseState";
import { ListQuery } from "../shared/Query";

interface CompositionState extends BaseState {
    compositions: Composition[];
    count: number;
}

const initialState: CompositionState = {
    loading: false,
    success: false,
    error: undefined,
    compositions: [],
    count: 0
}

// Get compositions
export const getAll = createAsyncThunk(
    "compositions/getAll",
    async (query: ListQuery, thunkAPI) => {
        try {
            const result = await compositionService.getAll(query, thunkAPI.dispatch);
            return result;
        } catch (error) {
            const message = narrowError(error);
            toast.error(message);
            return thunkAPI.rejectWithValue(message);
        }
    }
);

// Create composition
export const add = createAsyncThunk(
    "compositions/add",
    async (composition: Composition, thunkAPI) => {
        try {
            const created = await compositionService.add(composition, thunkAPI.dispatch);
            if (created?.id) {
                toast.success(`Composition ${created.id} added`);
            } else {
                toast.error(created?.message);
            }
            return created;
        } catch (error) {
            const message = narrowError(error);
            toast.error(message);
            return thunkAPI.rejectWithValue(message);
        }
    }
);

// Update composition
export const update = createAsyncThunk(
    "compositions/update",
    async (composition: Composition, thunkAPI) => {
        try {
            const updated = await compositionService.update(composition, thunkAPI.dispatch);
            if (updated?.id) {
                toast.success(`Composition ${updated.id} updated`);
            } else {
                toast.error(updated?.message);
            }
            return updated;
        } catch (error) {
            const message = narrowError(error);
            toast.error(message);
            return thunkAPI.rejectWithValue(message);
        }
    }
);

// Remove composition
export const remove = createAsyncThunk(
    "compositions/remove",
    async (id: string, thunkAPI) => {
        try {
            const result = await compositionService.remove(id, thunkAPI.dispatch);
            if (result.removed?.id) {
                toast.success(`Composition ${result.removed.id} removed`);
            } else {
                toast.error(result.message);
            }
            return result;
        } catch (error) {
            const message = narrowError(error);
            toast.error(message);
            return thunkAPI.rejectWithValue(message);
        }
    }
);

// Update product
// export const updateProduct = createAsyncThunk(
//     "admin/updateProduct",
//     async (product: IProduct, thunkAPI) => {
//         try {
//             const updatedProduct = await adminService.updateProduct(product, thunkAPI.dispatch);
//             if (updatedProduct?.id) {
//                 thunkAPI.dispatch(alert({text: "Product updated successfully", type: "success"}));
//             }
//             return updatedProduct;
//         } catch (error) {
//             const message = narrowError(error);
//             thunkAPI.dispatch(alert({text: message, type: "danger"}));
//             return thunkAPI.rejectWithValue(message);
//         }
//     }
// );

// Get all users
// export const getUsers = createAsyncThunk(
//     "admin/getUsers",
//     async (_, thunkAPI) => {
//         try {
//             return await adminService.getUsers(thunkAPI.dispatch);
//         } catch (error) {
//             const message = narrowError(error);
//             thunkAPI.dispatch(alert({text: message, type: "danger"}));
//             return thunkAPI.rejectWithValue(message);
//         }
//     }
// );

// Get user by id
// export const getUserById = createAsyncThunk(
//     "admin/getUserById",
//     async (id: string, thunkAPI) => {
//         try {
//             return await adminService.getUser(id, thunkAPI.dispatch);
//         } catch (error) {
//             const message = narrowError(error);
//             thunkAPI.dispatch(alert({text: message, type: "danger"}));
//             return thunkAPI.rejectWithValue(message);
//         }
//     }
// );

// Create user
// export const createUser = createAsyncThunk(
//     "admin/createUser",
//     async (user: IUser, thunkAPI) => {
//         try {
//             const createdUser = await adminService.createUser(user, thunkAPI.dispatch);
//             if (createdUser?.id) {
//                 thunkAPI.dispatch(alert({text: "User created successfully", type: "success"}));
//             }
//             return createdUser;
//         } catch (error) {
//             const message = narrowError(error);
//             thunkAPI.dispatch(alert({text: message, type: "danger"}));
//             return thunkAPI.rejectWithValue(message);
//         }
//     }
// );

// Update user
// export const updateUser = createAsyncThunk(
//     "admin/updateUser",
//     async (user: IUser, thunkAPI) => {
//         try {
//             const updatedUser = await adminService.updateUser(user, thunkAPI.dispatch);
//             if (updatedUser?.id) {
//                 thunkAPI.dispatch(alert({text: "User updated successfully", type: "success"}));
//             }
//             return updatedUser;
//         } catch (error) {
//             const message = narrowError(error);
//             thunkAPI.dispatch(alert({text: message, type: "danger"}));
//             return thunkAPI.rejectWithValue(message);
//         }
//     }
// );

export const compositionSlice = createSlice({
    name: "admin",
    initialState,
    reducers: {
        // updateCurrentProduct: (state, action: PayloadAction<IKeyValuePair<string>>) => {
        //     return {...state, product: {...state.product, ...action.payload}};
        // },
        // updateCurrentProductImages: (state, action: PayloadAction<{sort: number, url: string}>) => {
        //     let images = state.product.images;
        //     const {sort, url} = action.payload;
        //     if (images.find(i => i.sort === sort)) {
        //         if (!url) {
        //             images = images.filter(image => image.sort !== sort);
        //         } else {
        //             images = images.map(image => image.sort === sort ? action.payload : image);
        //         }
        //     } else {
        //         images = [...images, action.payload];
        //     }

        //     return {...state, product: {...state.product, images}};
        // },
        // // update user from the user details page
        // updateCurrentUser: (state, action: PayloadAction<IKeyValuePair<string | boolean>>) => {
        //     return {...state, user: {...state.user, ...action.payload}};
        // },
        // // update user from the users list
        // updateUserById: (state, action) => {
        //     const {id, ...rest} = action.payload;
        //     return {...state, users: state.users.map(u => u.id === id ? {...u, ...rest} : u)};
        // }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAll.pending, (state) => { state.loading = true; })
            .addCase(getAll.fulfilled, (state, action: PayloadAction<any>) => {
                const {compositions, count} = action.payload;
                return {...state, loading: false, success: true, compositions, count};
            })
            .addCase(getAll.rejected, (state, action) => {
                return {...state, loading: false, success: false, error: narrowError(action.payload)};
            })
            .addCase(add.pending, (state) => { state.loading = true; })
            .addCase(add.fulfilled, (state, action: PayloadAction<Composition | undefined>) => {
                return {...state, loading: false, success: !!action.payload?.id};
            })
            .addCase(add.rejected, (state, action) => {
                return {...state, loading: false, success: false, error: narrowError(action.payload)};
            })
            .addCase(update.pending, (state) => { state.loading = true; })
            .addCase(update.fulfilled, (state, action: PayloadAction<Composition | undefined>) => {
                return {...state, loading: false, success: !!action.payload?.id, compositions: [...state.compositions.map(c => c.id === action.payload?.id ? action.payload as Composition : c)]};
            })
            .addCase(update.rejected, (state, action) => {
                return {...state, loading: false, success: false, error: narrowError(action.payload)};
            })
            .addCase(remove.pending, (state) => { state.loading = true; })
            .addCase(remove.fulfilled, (state, action: PayloadAction<any>) =>{
                return {...state, loading: false, success: !!action.payload?.id, compositions: state.compositions.filter(c => c.id !== action.payload?.removed.id), count: action.payload?.count};
            })
            .addCase(remove.rejected, (state, action) => {
                return {...state, loading: false, success: false, error: narrowError(action.payload)};
            })
            // .addCase(getProducts.pending, (state) => { state.loading = true; })
            // .addCase(getProducts.fulfilled, (state, action: PayloadAction<IProduct[]>) => {
            //     return {...state, loading: false, success: true, products: action.payload || []};
            // })
            // .addCase(getProducts.rejected, (state, action: PayloadAction<unknown>) => {
            //     return {...state, loading: false, success: false, error: narrowError(action.payload)};
            // })
            // .addCase(getProductById.pending, (state) => { state.loading = true; })
            // .addCase(getProductById.fulfilled, (state, action: PayloadAction<IProduct>) => {
            //     return {...state, loading: false, success: true, product: action.payload || {} as IProduct};
            // })
            // .addCase(getProductById.rejected, (state, action: PayloadAction<unknown>) => {
            //     return {...state, loading: false, success: false, error: narrowError(action.payload)};
            // })
            // .addCase(updateProduct.pending, (state) => { state.loading = true; })
            // .addCase(updateProduct.fulfilled, (state, action: PayloadAction<IProduct | undefined>) =>{
            //     return {...state, loading: false, success: true, product: action.payload || {} as IProduct};
            // })
            // .addCase(updateProduct.rejected, (state, action) => {
            //     return {...state, loading: false, success: false, error: narrowError(action.payload)};
            // })
            // .addCase(getUsers.pending, (state) => { state.loading = true; })
            // .addCase(getUsers.fulfilled, (state, action: PayloadAction<IUser[]>) => {
            //     return {...state, loading: false, success: true, users: action.payload || []};
            // })
            // .addCase(getUsers.rejected, (state, action: PayloadAction<unknown>) => {
            //     return {...state, loading: false, success: false, error: narrowError(action.payload)};
            // })
            // .addCase(getUserById.pending, (state) => { state.loading = true; })
            // .addCase(getUserById.fulfilled, (state, action: PayloadAction<IUser>) => {
            //     return {...state, loading: false, success: true, user: action.payload || {} as IProduct};
            // })
            // .addCase(getUserById.rejected, (state, action: PayloadAction<unknown>) => {
            //     return {...state, loading: false, success: false, error: narrowError(action.payload)};
            // })
            // .addCase(updateUser.pending, (state) => { state.loading = true; })
            // .addCase(updateUser.fulfilled, (state, action: PayloadAction<IUser | undefined>) =>{
            //     return {...state, loading: false, success: true, user: action.payload || {} as IUser};
            // })
            // .addCase(updateUser.rejected, (state, action) => {
            //     return {...state, loading: false, success: false, error: narrowError(action.payload)};
            // })
            // .addCase(createUser.pending, (state) => { state.loading = true; })
            // .addCase(createUser.fulfilled, (state, action: PayloadAction<IUser | undefined>) =>{
            //     return {...state, loading: false, success: true, user: action.payload || {} as IUser};
            // })
            // .addCase(createUser.rejected, (state, action) => {
            //     return {...state, loading: false, success: false, error: narrowError(action.payload)};
            // })
    }
});

//export const { updateCurrentProduct, updateCurrentProductImages, updateCurrentUser, updateUserById } = compositionSlice.actions;
export default compositionSlice.reducer;