import React from "react";
import { useParams } from "react-router-dom";
import { Spinner } from "../../components/Spinner";
import { useAppSelector, useClient } from "../../hooks";
import { ClientForm } from "../../components/ClientForm";

export const Client = () => {    
    const { id } = useParams<{id: string}>();
    const { user } = useAppSelector(state => state.auth);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [client, loading, disabled, validationResult, onChange, onAddUser, onAddComposition, _, onValidate, onSubmit] = useClient(id);

    if (user?.clientId !== '1') {
        if (user?.clientId !== id ) { // TODO: replace 1 with something else
            return(<div className="d-flex w-100 h-100 align-items-center justify-content-center">
                <h6 className="">Unable to display client info</h6>
            </div>);
        }
    }

    return(loading ? <Spinner /> :
        <ClientForm 
            client={client} 
            disabled={disabled} 
            validationResult={validationResult} 
            onChange={onChange}
            onAddComposition={onAddComposition}
            onAddUser={onAddUser}
            onValidate={onValidate}
            onSubmit={onSubmit} />);
}