import React from "react";
import { useAppDispatch, useAppSelector, useModal } from "../../hooks";
import { Spinner } from "../../components/Spinner";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { guestService } from "../../features/guest/guestService";
import { Guest } from "../../types/Guest";
import { Role } from "../../types/User";
import { ConfirmRemoveFooter, GuestConfirmRemoveBody, Modal } from "../../components/Modal";

export const Guests = () => {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = React.useState(true);
    const [guests, setGuests] = React.useState<Guest[]>([]);
    const [selected, setSelected] = React.useState<Guest>({} as Guest);
    const { user } = useAppSelector(state => state.auth);
    const [modalRef, showModal, hideModal] = useModal();

    React.useEffect(() => {
        (async () => {
            const result = await guestService.getAll(dispatch);
            if (result) {
                setGuests(result);
            } else {
                toast.error('Could not get guests');
            }
        })();
        setLoading(false);
    }, [dispatch]);

    const handleUpdate = React.useCallback(async (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
        e.preventDefault();
        setLoading(true);
        const {name, checked} = e.target;
        const result = await guestService.update({[name]: checked, id}, dispatch);
        if (result) {
            setGuests(prev => prev.map(g => g.id === id ? result : g));
        }
        setLoading(false);
    }, [dispatch]);

    const handleRemove = React.useCallback((id: string) => {
        setSelected(guests.find(g => g.id === id) as Guest);
        showModal();
    }, [guests, showModal]);

    const handleConfirmRemove = React.useCallback(async () => {
        hideModal();
        setGuests(prev => prev.filter(o => o.id !== selected.id));
        const result = await guestService.remove(selected.id!, dispatch);
        if (result) {
            toast.success(`Guest ${result.id} removed`);
        }
        setSelected({} as Guest);        
    }, [dispatch, hideModal, selected.id]);
    
    return(loading ? <Spinner /> : 
        <div className="shadow-sm m-3 p-3 mb-1 rounded bg-white">
            <table className="table table-sm">
                <thead>
                    <tr className="align-middle">
                        <th></th>
                        <th>Name</th>
                        <th>Email</th>
                        <th className="text-center">Subscribed</th>
                        {user?.role === Role.Super && <th className="text-center text-danger"><i className="bi bi-trash" /></th>}
                    </tr>
                </thead>
                <tbody>
                    {guests?.length > 0 && guests.map((g, i) => 
                        <tr key={g.id} className="align-middle">
                            <td className="text-center"><Link to={`${g.id}`}>{i + 1}</Link></td>
                            <td>{g.first} {g.last}</td>
                            <td>{g.email}</td>
                            <td className="text-center"><input className="form-check-input" type="checkbox" name="subscribed" onChange={(e) => handleUpdate(e, g.id!)} checked={g.subscribed} /></td>
                            {user?.role === Role.Super && <td className="text-center">
                                <button className="btn btn-outline-danger btn-sm border-0" onClick={() => handleRemove(g.id!)}><i className="bi bi-x" /></button>
                            </td>}
                        </tr>)}
                </tbody>
            </table>
            <Modal 
                id={selected.id!} 
                title={'Remove Guest'}
                ref={modalRef} 
                body={() => <GuestConfirmRemoveBody {...selected} />}
                footer={ConfirmRemoveFooter({onConfirm: handleConfirmRemove, onHide: hideModal})}
                onConfirm={handleConfirmRemove} 
                onHide={hideModal} />
        </div>);
}