import React from "react";
import { useAppDispatch, useAppSelector, useModal } from "../../hooks";
import { toast } from "react-toastify";
import { invoiceService } from "../../features/invoice/invoiceService";
import { Spinner } from "../../components/Spinner";
import { Link, useNavigate } from "react-router-dom";
import { Role } from "../../types/User";
import { ConfirmRemoveFooter, InvoiceConfirmRemoveBody, Modal } from "../../components/Modal";
import { formatCurrency, formatDateString } from "../../utils/formatUtils";
import { Invoice } from "../../types/Invoice";
import { InvoiceStatusBadge } from "../../components/InvoiceStatusBadge";
import { Pagination, limits } from "../../components/Pagination";
import { InputClearable } from "../../components/InputClearable";
import { EllipsisDropdown } from "../../components/EllipsisDropdown";

export const Invoices = () => {
    const { user } = useAppSelector(state => state.auth);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(true);
    const [invoices, setInvoices] = React.useState<Invoice[]>([]);
    const [count, setCount] = React.useState<number>(0);
    const [selected, setSelected] = React.useState<Invoice>({} as Invoice);
    const [modalRef, showModal, hideModal] = useModal();
    
    const [searchText, setSearchText] = React.useState<string>('');    
    const [limit, setLimit] = React.useState<number>(limits[0]);
    const [currentPage, setCurrentPage] = React.useState<number>(1);

    React.useEffect(() => {
        (async () => {
            const result = await invoiceService.getAll({search: searchText, page: currentPage, limit}, dispatch);
            if (result) {
                setInvoices(result.invoices);
                setCount(result.count);
            } else {
                toast.error('Could not get invoices');
            }            
        })();
        setLoading(false);
        // eslint-disable-line react-hooks/exhaustive-deps
    }, [currentPage, limit, dispatch]);

    const handleRemove = React.useCallback((id: string) => {
        setSelected(invoices.find(o => o.id === id) as Invoice);
        showModal();
    }, [invoices, showModal]);

    const handleConfirmRemove = React.useCallback(async () => {
        hideModal();
        setInvoices(prev => prev.filter(o => o.id !== selected.id));
        const result = await invoiceService.remove(selected.id!, dispatch);
        if (result) {
            toast.success(`Invoice ${result.removed.id} removed`);
        }
        setSelected({} as Invoice);
        setCount(result.count);
    }, [dispatch, hideModal, selected.id]);

    const handleLimitChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault();
        setCurrentPage(1);
        setLimit(Number(e.target.value));
    }

    const handleSearchTextChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => { 
        e.preventDefault();
        setSearchText(e.target.value);
    }, []);

    const handleSearch = React.useCallback(async () => {
        setCurrentPage(1);
        const result = await invoiceService.getAll({search: searchText, page: 1, limit}, dispatch);
        if (result) {
            setInvoices(result.invoices);
            setCount(result.count);
        } else {
            toast.error('Could not get invoices');
        }
    }, [dispatch, limit, searchText]);

    const handleSearchTextClear = React.useCallback(async () => {
        setSearchText('')
        setCurrentPage(1);
        setLimit(10);
        const result = await invoiceService.getAll({}, dispatch);
        if (result) {
            setInvoices(result.invoices);
            setCount(result.count);
        } else {
            toast.error('Could not get invoices');
        }
    }, []);

    return(loading ? <Spinner /> : 
        <div className="m-3">
            <div className="d-flex align-items-center my-3">
                <div className="input-group input-group-sm w-50">
                    <InputClearable placeholder="Invoice #, Name, Email, etc." value={searchText} onChange={handleSearchTextChange} onClear={handleSearchTextClear} className="w-50" inputClassName="form-control-sm input-group-clearable" buttonClassName="btn-sm" />
                    <button className="btn btn-outline-secondary" onClick={handleSearch} type="button">Search</button>
                </div>
                <button className=" btn btn-primary btn-sm ms-auto" onClick={() => navigate("create")}><i className="bi-plus" /> New Invoice</button>
            </div>
            <table className="table table-sm">
                <thead>
                    <tr className="small">
                        <th>Invoice #</th>
                        <th>Status</th>
                        <th>Bill To</th>
                        <th>Created Date</th>
                        <th>Due Date</th>
                        <th>Total</th>
                        {user?.role === Role.Super && <th className="text-center"><i className="bi bi-menu-down" /></th>}
                    </tr>
                </thead>
                <tbody>
                    {invoices?.length > 0 && invoices.map((invoice, i) => 
                        <tr key={invoice.id} className="align-middle small">
                            <td><Link to={`${invoice.id}`}>{invoice.number}</Link></td>
                            <td><InvoiceStatusBadge status={invoice.status} /></td>
                            <td>{invoice.to.name}</td>
                            <td>{formatDateString(invoice.date)}</td>
                            <td>{invoice.dueDate ? formatDateString(invoice.dueDate) : ''}</td>
                            <td>{formatCurrency(invoice.total ?? -1)}</td>
                            {user?.role === Role.Super && <td className="text-center">
                                <EllipsisDropdown>
                                    <ul className="dropdown-menu">
                                        <li><button className="btn btn-sm dropdown-item" onMouseDown={() => handleRemove(invoice.id!)}>Remove</button></li>
                                    </ul>
                                </EllipsisDropdown>
                            </td>}
                        </tr>)}
                </tbody>
            </table>
            <div className="text-center my-3">
                <span className="float-start">{count} Invoices</span>
                <Pagination
                    currentPage={currentPage}
                    count={count}
                    pageSize={limit}
                    setCurrentPage={setCurrentPage}
                />
                {invoices?.length > 0 && <div className="d-flex align-items-center float-end">
                    <span className="mx-2 small">Show</span>
                    <select className="form-select form-select-sm" value={limit} style={{width: '70px'}} onChange={handleLimitChange}>
                        {limits.map(o => <option key={'limit-option-' + o} value={o}>{o}</option>)}
                    </select>
                </div>}
            </div>
            
            <Modal 
                id={selected.id!} 
                title={'Remove Invoice'}
                ref={modalRef} 
                body={() => <InvoiceConfirmRemoveBody id={selected.id!} />}
                footer={ConfirmRemoveFooter({onConfirm: handleConfirmRemove, onHide: hideModal})}
                onConfirm={handleConfirmRemove} 
                onHide={hideModal} />
        </div>);
}