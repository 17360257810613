import { Status } from "../types/Invoice";

export const InvoiceStatusBadge = ({status}: {status: Status}) => {
    let variant = 'secondary';
    switch (status) {
        case Status.Active:
            variant = 'primary';
            break;
        case Status.Paid:
            variant = 'success';
            break;
        case Status.PastDue:
            variant = 'danger';
            break;
        case Status.Void:
            variant = 'dark';
            break;
        default:
            break;
    }

    return(<span className={`badge bg-${variant}`}>{status}</span>);
}