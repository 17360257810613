import { Address } from "../types/Address";

const address1: Address = {
    address1: '5274 W PICO BLVD',
    address2: 'STE 206',
    city: 'LOS ANGELES',
    state: 'CA',
    zip: '90019'
}

const address2: Address = {
    address1: '9601 WILSHIRE BLVD',
    address2: 'STE 125',
    city: 'BEVERLY HILLS',
    state: 'CA',
    zip: '90210'
}

const address3: Address = {
    address1: '2215 W STAFFORD RD',
    address2: '',
    city: 'THOUSAND OAKS',
    state: 'CA',
    zip: '91361'
}

const address4: Address = {
    address1: '1723 OCEAN PARK BLVD',
    address2: 'APT 3A',
    city: 'SANTA MONICA',
    state: 'CA',
    zip: '90405'
}

const address5: Address = {
    address1: '2619 S FAIRVIEW ST',
    address2: 'STE 206',
    city: 'SANTA ANA',
    state: 'CA',
    zip: '92704'
}

export const testAddresses = () => [address1, address2, address3, address4, address5];
export const randomTestAddress = () => {
    const users = testAddresses();
    return users[Math.floor(Math.random() * users.length)];
}