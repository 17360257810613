import React from "react";

interface Props {
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onClear: () => void;
    placeholder?: string;
    className?: string;
    inputClassName?: string;
    buttonClassName?: string;
    disabled?: boolean;
}

export const InputClearable = ({value, onChange, onClear, placeholder, className, inputClassName, buttonClassName, disabled}: Props) => {
    return(
        <div className={`d-flex align-items-center position-relative ${className ? className : ''}`}>
            <input type="text" placeholder={placeholder} className={`d-inline-flex pe-4 form-control ${inputClassName ? inputClassName : ''}`} value={(value || '')} onChange={onChange} disabled={disabled} />
            {!disabled && <button type="button" className={`btn btn-input ${buttonClassName ? buttonClassName : ''}`} onClick={onClear}><i className="bi bi-x" /></button>}
        </div>
    );
}