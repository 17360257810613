export const capitalize = (s: string) => (s && s[0].toUpperCase() + s.slice(1)) || '';

/**
 * @description '1000' => '$1,000.00'
 * @param {number} amount
 * @returns {string}
 */
export const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-US',
        { style: 'currency', currency: 'USD' }
    ).format(amount);
}

export const centsToDollars = (cents: number) => {
    return formatCurrency(cents/100);
}

/**
 * @description 'Tue Aug 22 2023 20:56:55 GMT-0700 (Pacific Daylight Time)' => '2023-08-22'
 * @param {Date} date
 * @returns {string}
 */
export const formatInputDate = (date: Date) => {
    const year = date.toLocaleString('en-US', {year: 'numeric'});
    const month = date.toLocaleString('en-US', {month: '2-digit'});
    const day = date.toLocaleString('en-US', {day: '2-digit'});
    return `${year}-${month}-${day}`;
}

/**
 * @description 'Tue Aug 22 2023 20:56:55 GMT-0700 (Pacific Daylight Time)' => '08/22/2023'
 * @param {Date} date
 * @returns {string}
 */
export const formatDateString = (date: Date) => new Date(date).toLocaleString('en-US', {month: "2-digit", day:"2-digit", year:"numeric"});