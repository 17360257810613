import React from "react";
import { useParams } from "react-router-dom";
import { Spinner } from "../../components/Spinner";
import { CompositionForm } from "../../components/CompositionForm";
import { useComposition } from "../../hooks";

export const CompositionDetails = () => {    
    const { id } = useParams<{id: string}>();
    const [composition, loading, disabled, validationResult, onChange, onCheckedChange, onAddImage, onRemoveImage, onValidate, onSubmit] = useComposition(id);

    return(loading ? <Spinner /> :
        <CompositionForm 
            composition={composition} 
            disabled={disabled} 
            validationResult={validationResult} 
            onChange={onChange}
            onCheckedChange={onCheckedChange}
            onAddImage={onAddImage}
            onRemoveImage={onRemoveImage}
            onValidate={onValidate}
            onSubmit={onSubmit} />);
}