import React from "react";
import { Spinner } from "../../components/Spinner";
import { UserForm } from "../../components/UserForm";
import { useUser } from "../../hooks";

export const UserCreate = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [user, loading, disabled, validationResult, onChange, onValidate, onSubmit, onFake] = useUser();

    return(loading ? <Spinner /> :
        <UserForm 
            user={user} 
            disabled={disabled} 
            validationResult={validationResult} 
            onChange={onChange}
            onValidate={onValidate}
            onSubmit={onSubmit}
            onFake={onFake} />);
}