import { ValidationResult } from "./ValidationResult";

export interface Address {
    address1: string;
    address2?: string;
    city: string;
    state: string;
    zip: string;
}

export function validateAddress(address?: Partial<Address>) {
    const validationResult: ValidationResult<Address> = {
        valid: false,
        errors: {
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip: ''
        }
    };

    if (!address || Object.entries(address).length === 0) {
        for (const key in validationResult.errors) {
            // itemize errors by key to know exactly what is missing
            (validationResult.errors as any)[key] = validateAddressField({key, value: undefined});
        }    
        return validationResult;
    }

    let key: keyof Address;
    for (key in address) {
        const value = address ? address[key] : undefined;
        validationResult.errors[key] = validateAddressField({key, value});
    }    

    // no errors, validate
    if (Object.entries(validationResult.errors).every(e => !e[1])) {
        validationResult.valid = true;
    }

    return validationResult; 
}

export function validateAddressField({key, value}: {[key: string]: string | Address | undefined}): string {
    let error = '';

    switch (key) {
        case 'address1':
            if (!value) {
                error = 'Street address is required';
            }
            break;
        case 'city':
            if (!value) {
                error = 'City is required';
            }
            break;
        case 'state':
            if (!value) {
                error = 'State is required';
            }
            break;
        case 'zip':
            if (!value) {
                error = 'ZIP Code is required';
            }
            break;     
        default:
            break;
    }
    if(error) console.log('Address validation error', error);
    return error;
}