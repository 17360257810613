import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Layout } from "@stripe/stripe-js";
import React from "react";
import { Spinner } from "./Spinner";
import { useAppDispatch, useAppSelector } from "../hooks";
import { reset as resetGuest, update as updateGuest } from "../features/guest/guestSlice";
import { reset as resetCart } from "../features/cart/cartSlice";
import { narrowError } from "../utils/errorUtils";
import { useNavigate } from "react-router-dom";
import { Dev } from "./Dev";
import { randomTestGuest } from "../fake/guests";
import { formatCurrency } from "../utils/formatUtils";
import { Status } from "../types/Order";
import { orderService } from "../features/order/orderService";

enum Step {Shipping = 1, Payment = 2};

export const CheckoutForm = () => {
    const dispatch = useAppDispatch();
    const {cart, guest, order} = useAppSelector(state => state);
    const navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [step, setStep] = React.useState(Step.Shipping);

    const handleStepChange = React.useCallback((step: Step) => {
        setStep(step);
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const {name, value} = e.target;
        dispatch(updateGuest({[name]: value}));
    }    
    
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        
        const { paymentIntent, error } = await stripe.confirmPayment({
            elements,
            // confirmParams: {
            //     return_url: `${window.location.origin}/gallery/confirmation`,
            // },
            redirect: 'if_required'
        });

        // setting load state here as the element cannot rerender until stripe confirms payment ^^
        setLoading(true);
        const message = narrowError(error);
        if (error) {            
            if (error?.type === "card_error" || error?.type === "validation_error") {
                setMessage(error?.message || '');
                console.error('An error occured.', error);
            } else {
                setMessage("An unexpected error occured.");
                console.error('An unexpected error occured.', error);
            }
        }

        const toAdd = {
            ...guest,
            ...cart,
            paymentIntent: paymentIntent?.id, 
            error: message, 
            id: undefined,
            number: '',
            status: Status.New,
            createdAt: undefined
        };
        const result = await orderService.add(toAdd);
        if (result && result.paymentIntent) {
            navigate('/confirmation?id=' + result.paymentIntent);
            dispatch(resetCart());
            dispatch(resetGuest());
        } else {
            navigate('/confirmation?id=undefined');
        }

        setLoading(false);
    }

    const handleTest = () => dispatch(updateGuest(randomTestGuest()));

    const paymentElementOptions = {
        layout: 'tabs' as Layout
    }

    const stepRenderer = (step: Step) => {
        switch (step) {
            case Step.Payment:                
                return(
                    <>
                        <h5 className="mb-3">Payment Details</h5>
                        <Dev className="d-flex align-items-center">4242424242424242 {guest.zip}</Dev>
                        <PaymentElement id="payment-element" options={paymentElementOptions} />
                        {message && <div className="alert alert-danger d-flex align-items-center">
                            <i className="bi bi-exclamation-triangle fw-bold me-3" />
                            <div>{message}</div>
                        </div>}
                    </>
                );
            case Step.Shipping:
            default:
                const {first, last, email, phone, address1, address2, city, state, zip} = guest;
                return(
                    <>
                        <h5 className="mb-3">CONTACT</h5>
                        <div className="d-flex flex-column flex-md-row">
                            <div className="w-100 me-2 mb-2">
                                <label className="form-label">First name</label>
                                <input type="text" name="first" className="form-control stripe-style-input" placeholder="First name" onChange={handleChange} value={first} />
                            </div>
                            <div className="w-100 mb-2">
                                <label className="form-label">Last name</label>
                                <input type="text" name="last" className="form-control stripe-style-input" placeholder="Last Name" onChange={handleChange} value={last} />
                            </div>
                        </div>
                        <div className="d-flex mb-2">
                            <div className="w-100">
                                <label className="form-label">Email</label>
                                <input type="text" name="email" className="form-control stripe-style-input" placeholder="Email" onChange={handleChange} value={email} />
                            </div>
                        </div>
                        <div className="d-flex mb-2">
                            <div className="w-100">
                                <label className="form-label">Phone</label>
                                <input type="text" name="phone" className="form-control stripe-style-input" placeholder="Phone" onChange={handleChange} value={phone} />
                            </div>
                        </div>
                        <h5 className="mt-4 mb-3">SHIPPING</h5>
                        <div className="d-flex mb-2">
                            <div className="w-100">
                                <label className="form-label">Address</label>
                                <input type="text" name="address1" className="form-control stripe-style-input" placeholder="Address Line 1" onChange={handleChange} value={address1} />
                            </div>
                        </div>
                        <div className="d-flex mb-2">
                            <div className="w-100">
                                <label className="form-label">Address 2</label>
                                <input type="text" name="address2" className="form-control stripe-style-input" placeholder="Address Line 2" onChange={handleChange} value={address2} />
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row">
                            <div className="w-100 me-2 mb-2">
                                <label className="form-label">City</label>
                                <input type="text" name="city" className="form-control stripe-style-input" placeholder="City" onChange={handleChange} value={city} />
                            </div>
                            <div className="d-flex">
                                <div className="w-100 me-2 mb-2">
                                    <label className="form-label">State</label>
                                    <input type="text" name="state" className="form-control stripe-style-input" placeholder="State" onChange={handleChange} value={state} />
                                </div>
                                <div className="w-100 mb-2">
                                    <label className="form-label">ZIP</label>
                                    <input type="text" name="zip" className="form-control stripe-style-input" placeholder="ZIP Code" onChange={handleChange} value={zip} />
                                </div>
                            </div>
                        </div>
                    </>);
        }
    }

    const stepNavigationRenderer = (step: Step) => {
        switch (step) {
            case Step.Payment:                
                return(
                    <>
                        <button className="btn btn-outline-dark border-0 rounded-0 mt-2" onClick={() => handleStepChange(Step.Shipping)}>Back</button>
                        <button className="btn btn-outline-dark border-0 rounded-0 mt-2 ms-auto" onClick={handleSubmit} disabled={loading || !stripe || !elements} id="submit">
                            <span id="button-text">
                                {/* TODO: loading does not update button text */}
                                {loading ? "Processing ... " : "Pay now"}                                                                                                                                            {/* {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"} */}
                            </span>
                        </button>
                    </>);
            case Step.Shipping:
            default:
                return(
                    <>
                        <Dev className="d-flex align-items-center"><button onClick={handleTest}>Use Fake Data</button></Dev>
                        <button className="btn btn-outline-dark border-0 rounded-0 mt-2 ms-auto" onClick={() => handleStepChange(Step.Payment)}>Next</button>
                    </>);
        }
    }

    return(loading ? <Spinner /> : 
        <div className="d-flex flex-column w-100">
            <div className="d-flex flex-column flex-md-row align-items-start">
                <div className="d-flex flex-column w-100 mx-md-4 mb-2">
                    {stepRenderer(step)}
                </div>
                <div className="d-flex flex-column w-100 mx-md-4 mb-2">
                    <ItemsSummary />
                </div>
            </div>
            <div className="d-flex my-2">{stepNavigationRenderer(step)}</div>            
        </div>        
    );
}

const ItemsSummary = () => {
    const {items, subtotal, shipping, tax, total} = useAppSelector(state => state.cart);

    return(<>
        {items.map(item => 
            <div key={item.id} className="d-flex my-2">
                <img src={item.images[0].src} alt={item.images[0].alt} width={100}/>
                <div className="d-flex flex-column w-100 p-2">
                    <h6>{item.title}</h6>
                    <div className="d-flex mt-auto">
                        <small>X{item.quantity}</small>
                        <small className="ms-auto">{formatCurrency(item.price)}</small>
                    </div>
                </div>
            </div>)}
        <div className="mt-5">
            <div className="d-flex my-2">
                <h6>SUBTOTAL</h6>
                <h6 className="ms-auto">{formatCurrency(subtotal)}</h6>
            </div>
            <div className="d-flex my-2">
                <h6>SHIPPING</h6>
                <h6 className="ms-auto">{formatCurrency(shipping)}</h6>
            </div>
            <div className="d-flex my-2">
                <h6>ESTIMATED TAX</h6>
                <h6 className="ms-auto">{formatCurrency(tax)}</h6>
            </div>
            <div className="d-flex my-2">
                <h6>TOTAL</h6>
                <h6 className="ms-auto">{formatCurrency(total)}</h6>
            </div>
        </div>
    </>);
}