import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Cart, recalculateTotals } from "../../types/Cart";
import { CartItem } from "../../types/CartItem";
import { getStorageItem, removeStorageItem, setStorageItem } from "../../utils/storageUtils";

interface CartState extends Cart {}

const initialState: CartState = {
    items: [],
    subtotal: 0,
    shipping: 0,
    tax: 0,
    total: 0
}

const localCart = getStorageItem<Cart>('cart');

export const cartSlice = createSlice({
    name: 'cart',
    initialState: localCart ?? initialState,
    reducers: {
        add: (state, action: PayloadAction<CartItem>) => {
            const newItem = action.payload;
            const existingItem = state.items.some(i => i.id === newItem.id);
            let updatedItems: CartItem[] = [];
            existingItem
                ? updatedItems = state.items.map((i) => i.id === newItem.id ? {...newItem, quantity: i.quantity + newItem.quantity} : i)
                : updatedItems = [...state.items, newItem];

            state = {...state, items: updatedItems, ...recalculateTotals(updatedItems, state.shipping)};

            setStorageItem<Cart>('cart', state);
            return state;
        },
        update: (state, action: PayloadAction<CartItem>) => {
            const existingItem = action.payload;            
            const updatedItems = state.items.map((i) => i.id === existingItem.id ? existingItem : i);
            state = {...state, items: updatedItems, ...recalculateTotals(updatedItems, state.shipping)};
            
            setStorageItem<Cart>('cart', state);
            return state;
        },
        remove: (state, action: PayloadAction<string>) => {
            const id = action.payload;
            const updatedItems = state.items.filter((item) => item.id !== id);
            state = {...state, items: updatedItems, ...recalculateTotals(updatedItems, state.shipping)};
            setStorageItem<Cart>('cart', state);
            return state;
        },
        reset: (state) => {
            removeStorageItem('cart');
            state.items = [];
        }
    }
});
  
export const { add, update, remove, reset } = cartSlice.actions;
export default cartSlice.reducer;