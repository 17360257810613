import React from "react";
import { useAppDispatch, useAppSelector, useModal } from "../../hooks";
import { Client } from "../../types/Client";
import { Spinner } from "../../components/Spinner";
import { Role } from "../../types/User";
import { Link, useNavigate } from "react-router-dom";
import { ClientConfirmRemoveBody, ConfirmRemoveFooter, Modal } from "../../components/Modal";
import { clientService } from "../../features/clients/clientService";
import { toast } from "react-toastify";

export const Clients = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(true);
    const [clients, setClients] = React.useState<Client[]>([]);
    const [selected, setSelected] = React.useState<Client>({} as Client);
    const { user } = useAppSelector(state => state.auth);
    const [modalRef, showModal, hideModal] = useModal();

    React.useEffect(() => {
        (async () => {
            const result = await clientService.getAll(dispatch);
            if (result) {
                setClients(result);
            } else {
                toast.error('Could not get clients');
            }
            
        })();
        setLoading(false);
    }, [dispatch]);

    const handleRemove = React.useCallback((id: string) => {
        setSelected(clients.find(o => o.id === id) as Client);
        showModal();
    }, [clients, showModal]);

    const handleConfirmRemove = React.useCallback(async () => {
        hideModal();
        setClients(prev => prev.filter(o => o.id !== selected.id));
        const result = await clientService.remove(selected.id!, dispatch);
        if (result) {
            toast.success(`Client ${result.id} removed`);
        }
        setSelected({} as Client);        
    }, [dispatch, hideModal, selected.id]);
    
    return(loading ? <Spinner /> : 
        <div className="m-3">
            <button className=" btn btn-primary float-end my-3" onClick={() => navigate("create")}><i className="bi-plus" /> New Client</button>
            <table className="table table-sm">
                <thead>
                    <tr>
                        <th></th>
                        <th>Brand</th>
                        <th>Name</th>
                        <th>Active</th>
                        {user?.role === Role.Super && <th className="text-center text-danger"><i className="bi bi-trash" /></th>}
                    </tr>
                </thead>
                <tbody>
                    {clients?.length > 0 && clients.map((c, i) => 
                        <tr key={c.id} className="align-middle">
                            <td><Link to={`${c.id}`}>{i + 1}</Link></td>
                            <td>{c.brandName}</td>
                            <td>{c.name}</td>
                            <td>{c.isActive ? 'Active' : 'Inactive'}</td>
                            {user?.role === Role.Super && <td className="text-center">
                                <button className="btn btn-outline-danger btn-sm border-0" onClick={() => handleRemove(c.id!)}><i className="bi bi-x" /></button>
                            </td>}
                        </tr>)}
                </tbody>
            </table>    
            <Modal 
                id={selected.id!} 
                title={'Remove Client'}
                ref={modalRef} 
                body={() => <ClientConfirmRemoveBody id={selected.id!} brand={selected.brandName} name={selected.name} />}
                footer={ConfirmRemoveFooter({onConfirm: handleConfirmRemove, onHide: hideModal})}
                onConfirm={handleConfirmRemove} 
                onHide={hideModal} />
        </div>);
}