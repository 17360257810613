import React from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useComposition } from "../hooks";
import { ImagePreview } from "../components/ImagePreview";
import { Spinner } from "../components/Spinner";
import { add } from "../features/cart/cartSlice";
import { formatCurrency } from "../utils/formatUtils";

export const Composition: React.FC = () => {
    const {id} = useParams();
    const dispatch = useAppDispatch();
    const [composition, loading] = useComposition(id);
    const [qty, setQty] = React.useState(1);
    const {title, description, materials, images, width, height, year, price} = composition;

    const handleAdd = React.useCallback(() => {
        dispatch(add({ ...composition, quantity: qty }));
    }, [composition, dispatch, qty]);

    if (!composition) {
        return <>Composition not found</>
    }

    return(loading ? <Spinner /> :
        <div className="container">
            <div className="card mb-3 border-0 main-background">
                <div className="card-body d-flex flex-column flex-md-row g-0">
                    <div className="d-flex w-100">
                        {images?.length > 0 && <ImagePreview images={images} maxHeight={650} />}
                    </div>
                    <div className="d-flex flex-column w-100 ms-md-3">
                        <h5 className="card-title mt-3 mt-md-0">{title}</h5>
                        <p className="card-text">{materials}</p>
                        <div className="d-flex flex-md-column w-100">
                            <p className="card-text">{width} in. x {height} in.</p>
                            <p className="card-text ms-auto ms-md-0 mb-md-3">{year}</p>
                        </div>
                        <p className="card-text">{description}</p>
                        <div className="d-flex mt-auto justify-content-between align-items-center">
                            <div>{formatCurrency(price)}</div>
                            <div className="btn-group">
                                <button type="button" className="btn btn-outline-dark btn-sm border-0" disabled={qty === 1} onClick={() => setQty(prev => prev-1)}><i className="bi bi-dash" /></button>
                                <div className="align-middle py-2 px-3">{qty}</div>
                                <button type="button" className="btn btn-outline-dark btn-sm border-0" disabled={qty === 10} onClick={() => setQty(prev => prev+1)}><i className="bi bi-plus" /></button>
                            </div>
                            <button type="button" className="btn btn-outline-dark rounded-0 py-2 px-3 d-none d-md-inline" onClick={handleAdd}>ADD TO CART</button>
                        </div>
                        <button type="button" className="btn btn-clock btn-outline-dark rounded-0 py-2 px-3 mt-3 d-md-none" onClick={handleAdd}>ADD TO CART</button>
                    </div>
                </div>
            </div>
        </div>);
}