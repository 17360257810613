import axios from "axios";
import { getStorageItem } from "../../utils/storageUtils";
import { User } from "../../types/User";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { logout } from "../auth/authSlice";
import { narrowError } from "../../utils/errorUtils";
import { toast } from "react-toastify";

const API_URL = `${process.env.REACT_APP_API_DOMAIN}/api/users`;
axios.interceptors.request.use((config) => {
    const token = getStorageItem<User>('user')?.token;
    config.headers['Content-Type'] = 'application/json';
    config.headers["X-Auth-Token"] = token;
    config.headers["Ngrok-Skip-Browser-Warning"] = true;
    return config;
});

const getAll = async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>): Promise<User[] | undefined> => {
    try {
        const data = await axios
            .get(`${API_URL}`)
            .then(response => response.data)
            .catch((error) => {
                console.error(error);
                if (error.response.status === 401 || error.response.status === 403) {
                    dispatch(logout());
                }
            });
        return data;
    } catch (error) {
        const message = narrowError(error);
        toast.error(message);
        console.error('Could not get users', message);
    }   
}

const get = async (id: string, dispatch: ThunkDispatch<unknown, unknown, AnyAction>): Promise<User | undefined> => {
    try {
        const data = await axios
            .get(`${API_URL}/${id}`)
            .then(response => response.data)
            .catch((error) => {
                console.error(error);
                if (error.response.status === 401 || error.response.status === 403) {
                    dispatch(logout());
                }
            });
        return data;
    } catch (error) {
        const message = narrowError(error);
        toast.error(message);
        console.error('Could not get user', message);
    }   
}

const add = async (user: User, dispatch: ThunkDispatch<unknown, unknown, AnyAction>): Promise<User | undefined> => {    
    try {
        const data = await axios
            .post(`${API_URL}`, user)
            .then(response => response.data)
            .catch((error) => {
                console.error(error);
                if (error.response.status === 401 || error.response.status === 403) {
                    dispatch(logout());
                }
            });
        return data;
    } catch (error) {
        const message = narrowError(error);
        toast.error(message);
        console.error('Could not add user', message);
    }
}

const update = async (user: Partial<User>, dispatch: ThunkDispatch<unknown, unknown, AnyAction>): Promise<User | undefined> => {    
    try {
        const data: User = await axios.put(`${API_URL}/${user.id}`, user)
            .then(response => response.data)
            .catch((error) => {
                console.error(error);
                if (error.response.status === 401 || error.response.status === 403) {
                    dispatch(logout());
                }
            });

        return data;
    } catch (error) {
        const message = narrowError(error);
        toast.error(message);
        console.error('Could not update user', message);
    }
}

const remove = async (id: string, dispatch: ThunkDispatch<unknown, unknown, AnyAction>): Promise<User | undefined> => {
    try {
        const data = await axios.delete(`${API_URL}/${id}`)
            .then(response => response.data)
            .catch((error) => {
                console.error(error);
                if (error.response.status === 401 || error.response.status === 403) {
                    dispatch(logout());
                }
            });

        return data;
    } catch (error) {
        const message = narrowError(error);
        toast.error(message);
        console.error('Could not remove user', message);
    } 
}

export const userService = { getAll, get, add, update, remove };