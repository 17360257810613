import axios from "axios";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { logout } from "../auth/authSlice";
import { narrowError } from "../../utils/errorUtils";
import { Order } from "../../types/Order";
import { getStorageItem } from "../../utils/storageUtils";
import { User } from "../../types/User";
import { toast } from "react-toastify";
import { CartItem } from "../../types/CartItem";
import { Composition } from "../../types/Composition";

const API_URL = `${process.env.REACT_APP_API_DOMAIN}/api/orders`;
axios.interceptors.request.use((config) => {
    const token = getStorageItem<User>('user')?.token;
    config.headers['Content-Type'] = 'application/json';
    config.headers["X-Auth-Token"] = token;
    config.headers["Ngrok-Skip-Browser-Warning"] = true;
    return config;
});

const getAll = async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>): Promise<Order[] | undefined> => {
    try {
        const data = await axios
            .get(`${API_URL}`)
            .then(response => response.data)
            .catch((error) => {
                console.error(error);
                if (error.response.status === 401 || error.response.status === 403) {
                    dispatch(logout());
                }
            });
        return data;
    } catch (error) {
        const message = narrowError(error);
        toast.error(message);
        console.error('Could not get orders', message);
    }   
}

const get = async (id: string, dispatch: ThunkDispatch<unknown, unknown, AnyAction>): Promise<Order | undefined> => {
    try {
        const data = await axios
            .get(`${API_URL}/${id}`)
            .then(response => response.data)
            .catch((error) => {
                console.error(error);
                if (error.response.status === 401 || error.response.status === 403) {
                    dispatch(logout());
                }
            });
            // convert {item: {...}, quantity: X} to CartItem
            data.items = toCartItems(data.items);
        return data;
    } catch (error) {
        const message = narrowError(error);
        toast.error(message);
        console.error('Could not get order', message);
    }   
}

const add = async (order: Order) => {    
    try {
        const { data } = await axios.post(`${API_URL}`, order);
        return data;
    } catch (error) {
        const message = narrowError(error);
        toast.error(message);
        console.error('Could not add order', message);
    }
}

const update = async (order: Partial<Order>, dispatch: ThunkDispatch<unknown, unknown, AnyAction>): Promise<Order | undefined> => {    
    try {
        const data: Order = await axios.put(`${API_URL}/${order.id}`, order)
            .then(response => response.data)
            .catch((error) => {
                console.error(error);
                if (error.response.status === 401 || error.response.status === 403) {
                    dispatch(logout());
                }
            });

        data.items = toCartItems(data.items);
        return data;
    } catch (error) {
        const message = narrowError(error);
        toast.error(message);
        console.error('Could not update order', message);
    }
}

const confirm = async (paymentIntentId: string) => {
    try {
        const { data } = await axios.get(`${API_URL}/confirm/${paymentIntentId}`);
        return data;
    } catch (error) {
        const message = narrowError(error);
        toast.error(message);
        console.error('Could not confirm order', message);
    }
}

const remove = async (id: string, dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    try {
        const data = await axios.delete(`${API_URL}/${id}`)
            .then(response => response.data)
            .catch((error) => {
                console.error(error);
                if (error.response.status === 401 || error.response.status === 403) {
                    dispatch(logout());
                }
            });

        return data;
    } catch (error) {
        const message = narrowError(error);
        toast.error(message);
        console.error('Could not remove Order', message);
    } 
}

const toCartItems = (items: any[]) => {
    return items.map((i: {item: Composition, quantity: number}) => ({...i.item, quantity: i.quantity} as CartItem));
}

export const orderService = { getAll, get, add, update, confirm, remove };