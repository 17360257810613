import React from "react";
import { InvoiceValidationResult, ValidationResult } from "../types/ValidationResult";
import { Invoice, Status } from "../types/Invoice";
import { useAppSelector, useDropdownButton } from "../hooks";
import { Role } from "../types/User";
import { Dev } from "./Dev";
import { capitalize, formatCurrency, formatDateString, formatInputDate } from "../utils/formatUtils";
import { BusinessEntity } from "../types/BusinessEntity";
import { Link } from "react-router-dom";

interface InvoiceFormProps {
    invoice: Invoice;
    disabled: boolean;
    validationResult: InvoiceValidationResult;
    onAddLineItem: () => void;
    onRemoveLineItem: (sort: number) => void;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onStatusChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    onDueDateChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBusinessEntityChange: (key: keyof Invoice, e: React.ChangeEvent<HTMLInputElement>) => void;
    onLineItemChange: (sort: number, e: React.ChangeEvent<HTMLInputElement>) => void;
    onNotesChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onValidate: (invoice: Invoice | undefined) => boolean;
    onSubmit: (invoice: Invoice) => Promise<void>;
    onSend: (id: string) => Promise<void>;
    onFake: () => void;
}

export const InvoiceForm = ({invoice, disabled, validationResult, onAddLineItem, onRemoveLineItem, onChange, onStatusChange, onDueDateChange, onBusinessEntityChange, onLineItemChange, onNotesChange, onValidate, onSubmit, onSend, onFake}: InvoiceFormProps) => {
    const {user} = useAppSelector(state => state.auth);
    const {id, date, status, to, from, lineItems, dueDate, notes, stripePaymentLink} = invoice;
    const isSuperUser = user?.role === Role.Super;
    const formDisabled = !!invoice.id && !isSuperUser;
    const [dropdownRef, onDropdownToggle] = useDropdownButton();

    const handleLinkRedirect = React.useCallback((url: string) => {
        window.open(url, '_blank');
    }, []);

    const handleSubmit = React.useCallback(async (invoice?: Invoice) => {
        const valid = onValidate(invoice); // need to know if valid immediately

        if (valid) {
            await onSubmit(invoice!);
        }
    }, [onSubmit, onValidate]);

    return(
        <div className="d-flex bg-white rounded shadow p-3">
            <div className="d-flex flex-column w-100 p-2">
                <div className="d-flex align-items-start my-2">
                    <div className="d-flex flex-column">
                        {invoice?.number && <div className="d-flex align-items-center">
                            <small className="me-1"><b>Invoice:</b></small>
                            {invoice?.number}
                        </div>}
                        <div className="d-flex align-items-center">
                            <small className="me-1"><b>Date:</b></small>
                            {formatDateString(date)}
                        </div>
                    </div>
                    <div className="d-flex flex-column justify-content-center ms-auto">
                        <div className="d-flex align-items-center">
                            <small><b className="text-nowrap me-2 mb-2">Due Date:</b></small>
                            <input className={`form-control form-control-sm ${validationResult.errors?.dueDate ? 'is-invalid' : null}`} type="date" onChange={onDueDateChange} name="dueDate" value={dueDate ? formatInputDate(dueDate) : ''} disabled={formDisabled} />
                        </div>                        
                        <small className="text-danger ms-auto">{validationResult.errors?.dueDate}</small>
                        <div className="d-flex align-items-center mt-2">
                            <small><b className="text-nowrap me-2 mb-2">Status:</b></small>
                            <select className="form-select form-select-sm" name="status" value={status} onChange={onStatusChange} disabled={formDisabled}>
                                {Object.values(Status).map(s => <option key={s} value={s}>{s}</option>)}
                            </select>
                        </div>
                    </div>
                </div>
                <hr className="text-muted" />
                <div className="d-flex align-items-center my-2">
                    <InvoiceBusinessEntityForm entityKey={'to'} entity={to} validationResult={validationResult.errors?.to} onBusinessEntityChange={onBusinessEntityChange} formDisabled={formDisabled} className="me-5" />
                    <InvoiceBusinessEntityForm entityKey={'from'} entity={from} validationResult={validationResult.errors?.from} onBusinessEntityChange={onBusinessEntityChange} formDisabled={formDisabled} />
                </div>
                <hr className="text-muted" />
                <div className="d-flex flex-column mb-3">
                    <table className="table table-responsive table-hover">
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th style={{width: '5%'}}>Qty</th>
                                <th style={{width: '10%'}}>Unit Price</th>
                                {!formDisabled && <th style={{width: '5%'}}><button className="btn btn-primary btn-sm ms-auto" disabled={false} onClick={onAddLineItem}><i className="bi bi-plus" /></button></th>}
                            </tr>
                        </thead>
                        <tbody>
                            {lineItems?.length > 0 && lineItems?.map(i => {
                                const vr = validationResult.errors?.lineItems?.find(liv => liv.sort === i.sort)
                                return <tr key={i.sort}>
                                    <th>
                                        <div className="d-flex flex-column">
                                            <input className={`form-control form-control-sm ${vr?.errors?.name ? 'is-invalid' : null}`} type="text" onChange={(e) => onLineItemChange(i.sort, e)} name="name" placeholder="Name" value={i.name} disabled={formDisabled} />
                                            <small className="text-danger ms-auto fw-normal">{vr?.errors?.name}</small>
                                            <input className={`form-control form-control-sm mt-2 ${vr?.errors?.description ? 'is-invalid' : null}`} type="text" onChange={(e) => onLineItemChange(i.sort, e)} name="description" placeholder="Description" value={i.description} disabled={formDisabled} />
                                            <small className="text-danger ms-auto fw-normal">{vr?.errors?.description}</small>
                                        </div>
                                    </th>
                                    <th style={{width: '5%'}}>
                                        <input className={`form-control form-control-sm ${vr?.errors?.quantity ? 'is-invalid' : null}`} type="text" onChange={(e) => onLineItemChange(i.sort, e)} name="quantity" placeholder="Quantity" value={i.quantity} disabled={formDisabled} />
                                        <small className="text-danger ms-auto fw-normal">{vr?.errors?.quantity}</small>
                                    </th>
                                    <th style={{width: '10%'}}>
                                        <input className={`form-control form-control-sm ${vr?.errors?.price ? 'is-invalid' : null}`} type="text" onChange={(e) => onLineItemChange(i.sort, e)} name="price" placeholder="Unit Price" value={i.price} disabled={formDisabled} />
                                        <small className="text-danger ms-auto fw-normal">{vr?.errors?.price}</small>
                                    </th>
                                    {!formDisabled && <th style={{width: '5%'}}>{i.sort !== 1 && <button className="btn btn-outline-danger btn-sm border-0 ms-1" onClick={() => onRemoveLineItem(i.sort)}><i className="bi-x" /></button>}</th>}
                                </tr>
                            })}                            
                        </tbody>
                    </table>                    
                    <div className="d-flex my-2">
                        <div className="d-flex flex-column w-50 me-5">
                            <textarea className={`form-control form-control-sm ${validationResult.errors.notes ? 'is-invalid' : null}`} rows={3} name="notes" placeholder="Notes" value={notes} onChange={onNotesChange} disabled={formDisabled} />
                        </div>
                        <div className="d-flex flex-column w-50">
                            <small className="d-flex">
                                <b>Subtotal:</b>
                                <span className="ms-auto">{formatCurrency(invoice.subtotal ?? 0)}</span>
                            </small>
                            <small className="d-flex">
                                <b>Tax:</b>
                                <span className="ms-auto">{formatCurrency(invoice.tax ?? 0)}</span>
                            </small>
                            <small className="d-flex">
                                <b>Discount:</b>
                                <span className="ms-auto">{formatCurrency(invoice.discount ?? 0)}</span>
                            </small>
                            <span className="d-flex mt-3">
                                <b>Total:</b>
                                <span className="ms-auto">{formatCurrency(invoice.total ?? 0)}</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="d-flex align-items-center mt-auto">
                    {onFake && <Dev className="d-flex align-items-center"><button onClick={() => onFake()}>Use Fake Data</button></Dev>}
                    <div className="btn-group ms-auto">
                        <button className="btn btn-primary btn-sm" disabled={disabled} onClick={() => handleSubmit(invoice)} type="button">{id ? 'Update' : 'Create'}</button>
                        <button ref={dropdownRef} onBlur={onDropdownToggle} onClick={onDropdownToggle} type="button" className="btn btn-sm btn-primary dropdown-toggle dropdown-toggle-split">
                            <span className="visually-hidden">Toggle Dropdown</span>
                        </button>
                        <ul className="dropdown-menu">
                            {stripePaymentLink && <li><Link className="dropdown-item" to="." onMouseDown={() => handleLinkRedirect(stripePaymentLink)}><small>Stripe Payment Link</small></Link></li>}
                            <li><Link className={`dropdown-item ${!invoice?.number ? 'disabled' : ''}`} to="." onMouseDown={() => onSend(invoice.id!)}><small>Send to Customer</small></Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>);
}

interface InvoiceBusinessEntityFormProps extends React.HTMLAttributes<HTMLDivElement> {
    entityKey: keyof Invoice;
    entity: BusinessEntity;
    validationResult: ValidationResult<BusinessEntity>;
    onBusinessEntityChange: (key: keyof Invoice, e: React.ChangeEvent<HTMLInputElement>) => void;
    formDisabled: boolean;
}

const InvoiceBusinessEntityForm = ({entityKey, entity, validationResult, onBusinessEntityChange, className, formDisabled}: InvoiceBusinessEntityFormProps) => {
    return(
        <div className={`d-flex flex-column w-50 ${className}`}>
            <small className="form-label"><b>Bill {capitalize(entityKey)}</b></small>
            <input className={`form-control form-control-sm my-1 ${validationResult?.errors?.name ? 'is-invalid' : null}`} type="text" onChange={(e) => onBusinessEntityChange(entityKey, e)} name="name" placeholder="Company Name" value={entity.name} disabled={formDisabled} />
            <small className="text-danger ms-auto" >{validationResult?.errors?.name}</small>
            <div className="d-flex my-1">
                <div className="d-flex flex-column w-50 me-2">
                    <input className={`form-control form-control-sm ${validationResult?.errors?.first ? 'is-invalid' : null}`} type="text" onChange={(e) => onBusinessEntityChange(entityKey, e)} name="first" placeholder="First Name" value={entity.first} disabled={formDisabled} />
                    <small className="text-danger ms-auto">{validationResult?.errors?.first}</small>
                </div>
                <div className="d-flex flex-column w-50">
                    <input className={`form-control form-control-sm ${validationResult?.errors?.last ? 'is-invalid' : null}`} type="text" onChange={(e) => onBusinessEntityChange(entityKey, e)} name="last" placeholder="Last Name" value={entity.last} disabled={formDisabled} />
                    <small className="text-danger ms-auto">{validationResult?.errors?.last}</small>
                </div>
            </div>
            <div className="d-flex my-1">
                <div className="d-flex flex-column w-50 me-2">
                    <input className={`form-control form-control-sm ${validationResult?.errors?.address1 ? 'is-invalid' : null}`} type="text" onChange={(e) => onBusinessEntityChange(entityKey, e)} name="address1" placeholder="Address Line 1" value={entity.address1} disabled={formDisabled} />
                    <small className="text-danger ms-auto">{validationResult?.errors?.address1}</small>
                </div>
                <div className="d-flex flex-column w-50">
                    <input className={`form-control form-control-sm ${validationResult?.errors?.address2 ? 'is-invalid' : null}`} type="text" onChange={(e) => onBusinessEntityChange(entityKey, e)} name="address2" placeholder="Address Line 2" value={entity.address2} disabled={formDisabled} />
                    <small className="text-danger ms-auto">{validationResult?.errors?.address2}</small>
                </div>
            </div>
            <div className="d-flex my-1">
                <div className="d-flex flex-column w-50 me-2">
                    <input className={`form-control form-control-sm ${validationResult?.errors?.city ? 'is-invalid' : null}`} type="text" onChange={(e) => onBusinessEntityChange(entityKey, e)} name="city" placeholder="City" value={entity.city} disabled={formDisabled} />
                    <small className="text-danger ms-auto">{validationResult?.errors?.city}</small>
                </div>
                <div className="d-flex flex-column w-25">
                    <input className={`form-control form-control-sm ${validationResult?.errors?.state ? 'is-invalid' : null}`} type="text" onChange={(e) => onBusinessEntityChange(entityKey, e)} name="state" placeholder="State" value={entity.state} disabled={formDisabled} />
                    <small className="text-danger ms-auto">{validationResult?.errors?.state}</small>
                </div>
                <div className="d-flex flex-column w-25 ms-2">
                    <input className={`form-control form-control-sm ${validationResult?.errors?.zip ? 'is-invalid' : null}`} type="text" onChange={(e) => onBusinessEntityChange(entityKey, e)} name="zip" placeholder="ZIP Code" value={entity.zip} disabled={formDisabled} />
                    <small className="text-danger ms-auto">{validationResult?.errors?.zip}</small>
                </div>
            </div>
            <div className="d-flex my-1">
                <div className="d-flex flex-column w-50 me-2">
                    <input className={`form-control form-control-sm ${validationResult?.errors?.phone ? 'is-invalid' : null}`} type="text" onChange={(e) => onBusinessEntityChange(entityKey, e)} name="phone" placeholder="Phone" value={entity.phone} disabled={formDisabled} />
                    <small className="text-danger ms-auto">{validationResult?.errors?.phone}</small>
                </div>
                <div className="d-flex flex-column w-50">
                    <input className={`form-control form-control-sm ${validationResult?.errors?.email ? 'is-invalid' : null}`} type="text" onChange={(e) => onBusinessEntityChange(entityKey, e)} name="email" placeholder="Email" value={entity.email} disabled={formDisabled} />
                    <small className="text-danger ms-auto">{validationResult?.errors?.email}</small>
                </div>
            </div>
        </div>
    );
}