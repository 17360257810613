import React from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import { add } from "../features/cart/cartSlice";
import { formatCurrency } from "../utils/formatUtils";
import { getAll } from "../features/compositions/compositionSlice";
import { CompositionTile } from "./Compositions";
import { Spinner } from "../components/Spinner";
import { Composition, initComposition } from "../types/Composition";

export const Home = () => {
    const style = {
        position: 'absolute',
        top: 0,
        left: 0,
        //zIndex: 99, 
    } as React.CSSProperties;

    const dispatch = useAppDispatch();
    const { compositions, loading } = useAppSelector(state => state.compositions);
    const [featured, setFeatured] = React.useState(initComposition(''));
    const [related, setRelated] = React.useState<Composition[]>([]);

    React.useEffect(() => {
        if(compositions?.length === 0) {
            dispatch(getAll({}));
        }        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if(compositions?.length > 0) {
            let random1 = [...compositions]?.sort(() => 0.5 - Math.random()).slice(0, 1)[0];
            let random3 = [...compositions]?.sort(() => 0.5 - Math.random()).slice(0, 3);
            setFeatured(random1);
            setRelated(random3);
        }        
    }, [compositions]);

    const handleAdd = React.useCallback(() => {
        dispatch(add({ ...featured, quantity: 1 }));
    }, [featured, dispatch]);

    return(loading ? <Spinner /> : <div>
        <div className="d-flex mb-3">
            <img className="img-fluid" style={style} width="800" src={featured?.images[0].src} alt={featured?.images[0].alt} />
            <div className="d-flex flex-column align-items-center ms-auto" style={{margin: '10rem 10rem 5rem'}}>
                <img width="500" src={featured?.images[1]?.src} alt={featured?.images[1]?.alt} />
                <span className="display-6">{featured?.title}</span>
            </div>
        </div>
        <div className="container">
            <div className="d-flex flex-column flex-lg-row mb-5">
                <div className="w-lg-50 mx-2" style={{textAlign: 'justify'}}>{featured?.description}</div>
                <div className="d-flex flex-column align-items-center mx-auto mx-2 px-3 w-lg-50 mt-2 mt-lg-0" style={{maxWidth: '500px'}}>
                    <div className="my-1">{featured?.materials}</div>
                    <div className="my-1">{`${featured?.width} in. x ${featured?.height} in.`}</div>
                    <div className="mt-auto my-2">{formatCurrency(featured?.price)}</div>
                    <button type="button" className="btn btn-outline-dark w-100 rounded-0 py-2 px-3 d-block mt-auto" onClick={handleAdd}>ADD TO CART</button>
                </div>
            </div>
            <div className="my-2">RELATED COMPOSITIONS</div>
            <div className="d-flex justify-content-center my-3">
                {related?.length > 0 
                    ? related.map(({id, images, title}) => (
                        <CompositionTile key={id} id={id} title={title} images={images} />
                    )) 
                    : <span>No compositions found</span>}
            </div>
        </div>
    </div>);
}