import { InvoiceForm } from "../../components/InvoiceForm";
import { Spinner } from "../../components/Spinner";
import { useInvoice } from "../../hooks";

export const InvoiceCreate = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [invoice, loading, disabled, validationResult, onAddLineItem, onRemoveLineItem, onChange, onStatusChange, onDueDateChange, onBusinessEntityChange, onLineItemChange, onNotesChange, onValidate, onSubmit, onSend, onFake] = useInvoice();

    return(loading ? <Spinner /> :
        <InvoiceForm 
            invoice={invoice} 
            disabled={disabled} 
            validationResult={validationResult}
            onAddLineItem={onAddLineItem}
            onRemoveLineItem={onRemoveLineItem}
            onChange={onChange}
            onStatusChange={onStatusChange}
            onDueDateChange={onDueDateChange}
            onBusinessEntityChange={onBusinessEntityChange}
            onLineItemChange={onLineItemChange}
            onNotesChange={onNotesChange}
            onValidate={onValidate}
            onSubmit={onSubmit}
            onSend={onSend}
            onFake={onFake} />);
}