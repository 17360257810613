import { Link } from "react-router-dom";
import { Spinner } from "../components/Spinner";
import { useAppDispatch, useAppSelector } from "../hooks";
import React from "react";
import { getAll } from "../features/compositions/compositionSlice";
import { Image } from "../types/Image";

export const Compositions = () => {
    const dispatch = useAppDispatch();
    const { compositions, loading } = useAppSelector(state => state.compositions);

    React.useEffect(() => {
        if(compositions?.length === 0) {
            dispatch(getAll({limit: 1000}));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(loading ? <Spinner /> : 
        <div className="row justify-content-center mt-5">
            {compositions?.length > 0 
                ? compositions.map(({id, images, title, materials, price}) => (
                    <CompositionTile key={id} id={id} title={title} images={images} />
                )) 
                : <span>No compositions found</span>}
        </div>);
}

export const CompositionTile = ({id, title, images}: {id: string; title: string; images: Image[]; }) => {
    return(
        <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3 m-md-5 m-2" key={id}>
            <Link to={`/compositions/${id}`} className="nav-link ms-auto">
                <div className="image-container">
                    <img src={images[0]?.src} className="image" alt={images[0].alt} />
                    <div className="image-container-overlay">
                        <h6>{title}</h6>
                    </div>
                </div>
            </Link>
        </div>
    );
}