import React from "react";
import { Dev } from "./Dev";
import { User } from "../types/User";
import { ValidationResult } from "../types/ValidationResult";

interface UserFormProps {
    user: User;
    disabled: boolean;
    validationResult: ValidationResult<User & {password: string;}>;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onValidate: (user?: User & {password: string;}) => boolean;
    onSubmit: (user: User & {password: string;}) => Promise<void>;
    onFake?: (clientId: string) => void;
}

export const UserForm = ({user, disabled, validationResult, onChange, onValidate, onSubmit, onFake}: UserFormProps) => {
    const [password, setPassword] = React.useState('');    

    return(<>
        <div className="d-flex flex-column mb-3">
            <div className="d-flex flex-column shadow-sm p-3 m-3 bg-white rounded">
                <strong>Credentials</strong>
                <div className="d-flex my-1">
                    <div className="w-50 me-2">
                        <small className="form-label">Email</small>
                        <input type="text" name="email" className={`form-control form-control-sm ${validationResult.errors.email ? 'is-invalid' : null}`} value={user.email} onChange={onChange} />
                        <small className="text-danger">{validationResult.errors.email}</small>
                    </div>
                    <div className="w-50 me-2">
                        <small className="form-label">Password</small>
                        <input type="text" name="password" className={`form-control form-control-sm ${validationResult.errors.password ? 'is-invalid' : null}`} value={password} onChange={(e) => setPassword(e.target.value)} />
                        <small className="text-danger">{validationResult.errors.password}</small>
                    </div>
                </div>
            </div>
            
            <div className="d-flex flex-column shadow-sm p-3 m-3 bg-white rounded">
                <strong>Personal Details</strong>
                <div className="d-flex my-1">
                    <div className="w-50 me-2">
                        <small className="form-label">First Name</small>
                        <input type="text" name="first" className={`form-control form-control-sm ${validationResult.errors.first ? 'is-invalid' : null}`} value={user.first} onChange={onChange} />
                        <small className="text-danger">{validationResult.errors.first}</small>
                    </div>
                    <div className="w-50 me-2">
                        <small className="form-label">Last Name</small>
                        <input type="text" name="last" className={`form-control form-control-sm ${validationResult.errors.last ? 'is-invalid' : null}`} value={user.last} onChange={onChange} />
                        <small className="text-danger">{validationResult.errors.last}</small>
                    </div>
                </div>
            </div>
        </div>
        <div className="d-flex align-items-center mt-auto">
            {onFake && <Dev className="d-flex align-items-center"><button onClick={() => onFake(user.clientId)}>Use Fake Data</button></Dev>}
            <button className="btn btn-primary ms-auto" disabled={disabled} onClick={() => onSubmit({...user, password})}>Submit</button>
        </div>
    </>);
}