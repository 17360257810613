import React from "react";
import { useAppDispatch, useAppSelector, useModal } from "../../hooks";
import { getAll, remove } from "../../features/compositions/compositionSlice";
import { Link, useNavigate } from "react-router-dom";
import { Spinner } from "../../components/Spinner";
import { Role } from "../../types/User";
import { formatCurrency } from "../../utils/formatUtils";
import { CompositionConfirmRemoveBody, ConfirmRemoveFooter, Modal } from "../../components/Modal";
import { Composition } from "../../types/Composition";
import { Pagination, limits } from "../../components/Pagination";
import { InputClearable } from "../../components/InputClearable";
import { EllipsisDropdown } from "../../components/EllipsisDropdown";

export const CompositionsList = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { compositions, loading, count } = useAppSelector(state => state.compositions);
    const { user } = useAppSelector(state => state.auth);
    const [selected, setSelected] = React.useState<Composition>({} as Composition);
    const [modalRef, showModal, hideModal] = useModal();

    const [searchText, setSearchText] = React.useState<string>('');    
    const [limit, setLimit] = React.useState<number>(limits[0]);
    const [currentPage, setCurrentPage] = React.useState<number>(1);

    React.useEffect(() => {
        dispatch(getAll({search: searchText, page: currentPage, limit}));
    }, [currentPage, limit, dispatch]);

    const handleRemove = React.useCallback((id: string) => {
        setSelected(compositions.find(o => o.id === id) as Composition);
        showModal();
    }, [compositions, showModal]);

    const handleConfirmRemove = React.useCallback(() => {
        hideModal();
        dispatch(remove(selected.id!));
        setSelected({} as Composition);
    }, [dispatch, hideModal, selected.id]);

    const handleLimitChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault();
        setCurrentPage(1);
        setLimit(Number(e.target.value));
    }

    const handleSearchTextChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => { 
        e.preventDefault();
        setSearchText(e.target.value);
    }, []);

    const handleSearch = React.useCallback(async () => {
        setCurrentPage(1);
        dispatch(getAll({search: searchText, page: 1, limit}));
    }, [dispatch, limit, searchText]);

    const handleSearchTextClear = React.useCallback(async () => {
        setSearchText('');
        setCurrentPage(1);
        setLimit(10);
        dispatch(getAll({}));
    }, []);

    return(loading ? <Spinner /> : 
        <div className="m-3">
            <div className="d-flex align-items-center my-3">
                <div className="d-flex input-group input-group-sm w-50">
                    <InputClearable placeholder="Title, Description, or Materials" value={searchText} onChange={handleSearchTextChange} onClear={handleSearchTextClear} className="w-50" inputClassName="form-control-sm input-group-clearable" buttonClassName="btn-sm" />
                    <button className="btn btn-outline-secondary" onClick={handleSearch} type="button">Search</button>
                </div>
                <button className="btn btn-primary btn-sm ms-auto" onClick={() => navigate("create")}><i className="bi-plus" /> New Composition</button>
            </div>
            <table className="table table-sm">
                <thead>
                    <tr>
                        <th>Image</th>
                        <th>Title</th>
                        <th>W x H</th>
                        <th>Year</th>
                        <th className="text-center">Price</th>
                        {user?.role === Role.Super && <th className="text-center"><i className="bi bi-menu-down" /></th>}
                    </tr>
                </thead>
                <tbody>
                    {compositions?.length > 0 && compositions.map((c, i) => 
                        <tr key={c.id} className="align-middle">
                            <td>
                                <img width={50} src={c.images[0].src} alt={c.images[0].alt} className="img-fluid hover-zoom" />
                            </td>
                            <td>
                                <Link to={`${c.id}`}>
                                    {c.title}
                                </Link>
                            </td>
                            <td>{`${c.width} in. x ${c.height} in.`}</td>
                            <td>{c.year}</td>
                            <td className="text-end">{formatCurrency(c.price)}</td>
                            {user?.role === Role.Super && <td className="text-center">
                                <EllipsisDropdown>
                                    <ul className="dropdown-menu">
                                        <li><button className="btn btn-sm dropdown-item" onMouseDown={() => handleRemove(c.id!)}>Remove</button></li>
                                    </ul>
                                </EllipsisDropdown>
                                {/* <button className="btn btn-outline-danger btn-sm border-0" onClick={() => handleRemove(c.id)}><i className="bi-x" /></button> */}
                            </td>}
                        </tr>)}
                </tbody>
            </table>
            <div className="text-center my-3">
                <span className="float-start">{count} Compositions</span>
                <Pagination
                    currentPage={currentPage}
                    count={count}
                    pageSize={limit}
                    setCurrentPage={setCurrentPage}
                />
                {compositions?.length > 0 && <div className="d-flex align-items-center float-end">
                    <span className="mx-2 small">Show</span>
                    <select className="form-select form-select-sm" value={limit} style={{width: '70px'}} onChange={handleLimitChange}>
                        {limits.map(o => <option key={'limit-option-' + o} value={o}>{o}</option>)}
                    </select>
                </div>}
            </div>

            <Modal 
                id={selected.id!} 
                title={'Remove Composition'} 
                ref={modalRef} 
                body={() => <CompositionConfirmRemoveBody id={selected.id!} title={selected.title} price={selected.price} />}
                footer={ConfirmRemoveFooter({onConfirm: handleConfirmRemove, onHide: hideModal})}
                onConfirm={handleConfirmRemove} 
                onHide={hideModal} />
        </div>);
}