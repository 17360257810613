import React from "react";

/*
    Four possible component states:

    Case 1: < 1* 2 3 4 5 >
    Case 2: < 1 2 3* 4 5 ... 50 >
    Case 3: < 1 ... 46 47 48 49* 50 >
    Case 4: < 1 ... 14 15* 16 ... 100 >

    * - active page number
*/

interface PaginationProps {
    count: number;                                                      // total number of results from source
    currentPage: number;                                                // currently active page (1-based index)
    pageSize: number;                                                   // max number of results per page
    setCurrentPage:  React.Dispatch<React.SetStateAction<number>>;      // page value change
    siblingCount?: number;                                              // number of pages to the left and the right of active page
    className?: string;     
}

export const limits = [10, 20, 50];
export const DOTS = '...';
export const usePagination = ({count, pageSize, currentPage, siblingCount = 1}: Omit<PaginationProps, 'setCurrentPage'>): (string | number)[] | undefined => {
    const paginationRange = React.useMemo(() => {
        const totalPageCount = Math.ceil(count / pageSize);
        // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
        const totalPageNumbers = siblingCount + 5;

        /*
            Case 1:
            If the number of pages is less than the page numbers we want to show in our
            paginationComponent, we return the range [1..totalPageCount]
        */
        if (totalPageNumbers >= totalPageCount) {
            return range(1, totalPageCount);
        }
        
        // Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
        const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
        const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

        // We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and totalPageCount. 
        // Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
        const shouldShowLeftDots = leftSiblingIndex > 2;
        const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

        const firstPageIndex = 1;
        const lastPageIndex = totalPageCount;

        /*
            Case 2: No left dots to show, but rights dots to be shown
        */
        if (!shouldShowLeftDots && shouldShowRightDots) {
        let leftItemCount = 3 + 2 * siblingCount;
        let leftRange = range(1, leftItemCount);

        return [...leftRange, DOTS, totalPageCount];
        }

        /*
            Case 3: No right dots to show, but left dots to be shown
        */
        if (shouldShowLeftDots && !shouldShowRightDots) {        
            let rightItemCount = 3 + 2 * siblingCount;
            let rightRange = range(
                totalPageCount - rightItemCount + 1,
                totalPageCount
            );
            return [firstPageIndex, DOTS, ...rightRange];
        }
        
        /*
            Case 4: Both left and right dots to be shown
        */
        if (shouldShowLeftDots && shouldShowRightDots) {
            let middleRange = range(leftSiblingIndex, rightSiblingIndex);
            return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
        }
         
    }, [count, pageSize, siblingCount, currentPage]);

    return paginationRange;
}

export const Pagination = ({count, pageSize, currentPage, setCurrentPage, siblingCount = 1}: PaginationProps) => {
    const paginationRange = usePagination({currentPage, count, siblingCount, pageSize});
    
    const onNext = React.useCallback(() => {
        setCurrentPage(prev => prev + 1);
    }, []);
    
    const onPrevious =  React.useCallback(() => {
        setCurrentPage(prev => prev - 1);
    }, []);
    
    // If there are less than 2 times in pagination range we shall not render the component
    if (currentPage === 0 || paginationRange == undefined || paginationRange.length < 2) {
        return null;
    }

    const lastPage = paginationRange[paginationRange.length - 1];
    return(
        <nav className="d-inline-block">
            <ul className="pagination pagination-sm mb-0">
                <li className={currentPage === 1 ? 'page-item disabled' : 'page-item'}>
                    <button type="button" className="page-link" onClick={onPrevious} disabled={currentPage === 1}>&laquo;</button>
                </li>
                {paginationRange.map((page, i) => {
                    if (page === DOTS) {
                        return <li key={'page-item-' + i} className="page-item disabled"><button type="button" className="page-link" disabled>&#8230;</button></li>;
                    }

                    return(
                        <li key={'page-item-' + i} className="page-item">
                            <button type="button" className={currentPage === page ? 'page-link active' : 'page-link'} onClick={() => setCurrentPage(page as number)}>{page}</button>
                        </li>);
                })}
                <li className={currentPage === lastPage ? 'page-item disabled' : 'page-item'}>
                    <button type="button" className="page-link" onClick={onNext} disabled={currentPage === lastPage}>&raquo;</button>
                </li>
            </ul>
        </nav>
    );
}

// Create array of 'length' length and set its elements based on start value
const range = (start: number, end: number) => {
    let length = end - start + 1;
    return Array.from({ length }, (_, i) => i + start);
};