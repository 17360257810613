import React from "react";
import { useDropdown } from "../hooks";

export const EllipsisDropdown = ({children}: {children: React.ReactNode}) => {
    const [dropdownRef, onDropdownToggle] = useDropdown();

    return(
        <div className="dropdown" ref={dropdownRef}>
            <button className="btn btn-light btn-sm border-0 dropdown-toggle caret-off" type="button" onBlur={onDropdownToggle} onClick={onDropdownToggle}>
                <i className="bi bi-three-dots-vertical" />
            </button>
            {children}
        </div>);
}