import { Role, User } from "../types/User";

const user1: User = {
    id: '',
    clientId: '1',
    first: 'Angela',
    last: 'Anderson',
    email: 'angela.anderson@example.com',
    isActive: true,
    token: '',
    role: Role.Viewer
}

const user2: User = {
    id: '',
    clientId: '1',
    first: 'David',
    last: 'Jefferson',
    email: 'david.jefferson@example.com',
    isActive: true,
    token: '',
    role: Role.Viewer
}

const user3: User = {
    id: '',
    clientId: '1',
    first: 'Ben',
    last: 'Zamin',
    email: 'ben.zamin@example.com',
    isActive: true,
    token: '',
    role: Role.Viewer
}

const user4: User = {
    id: '',
    clientId: '1',
    first: 'Fred',
    last: 'Bread',
    email: 'fred.bread@example.com',
    isActive: true,
    token: '',
    role: Role.Viewer
}

export const testUsers = () => [user1, user2, user3, user4];
export const randomTestUser = () => {
    const users = testUsers();
    return users[Math.floor(Math.random() * users.length)];
}