import { ValidationResult } from "./ValidationResult";

export interface User {
    id: string;
    clientId: string;
    email: string;
    first: string;
    last: string;
    isActive: boolean;
    token: string;
    role: Role;
}

export enum Role {
    Super = 'Super',
    Admin = 'Admin',
    Viewer = 'Viewer',
}

export function validateUser(user?: Partial<User & { password: string; }>) {
    const validationResult: ValidationResult<User & { password: string; }> = {
        valid: false,
        errors: { id: '', clientId: '', first: '', last: '', email: '', role: '', isActive: '', token: '', password: '' }
    };

    if (!user || Object.entries(user).length === 0) {
        for (const key in validationResult.errors) {
            (validationResult.errors as any)[key] = validateUserField({ key, value: undefined });
        }
        return validationResult;
    }

    let key: keyof User | 'password';
    for (key in user) {
        const value = user ? user[key] : undefined;
        validationResult.errors[key] = validateUserField({ key, value });
    }

    // no errors, validate
    if (Object.entries(validationResult.errors).every(e => !e[1])) {
        validationResult.valid = true;
    }

    return validationResult;
}

export function validateUserField({ key, value }: { [key: string]: string | boolean | undefined }): string {
    let error = '';

    switch (key) {
        case 'first':
            if (!value) {
                error = 'First name is required';
            }
            break;
        case 'last':
            if (!value) {
                error = 'Last name is required';
            }
            break;
        case 'email':
            if (!value) {
                error = 'Email is required';
            }
            break;
        case 'password':
            if (!value) {
                error = 'Password is required';
            }
            break;
        case 'clientId':
        case 'id':
        default:
            break;
    }
    if(error) console.log('User validation error', error);
    return error;
}