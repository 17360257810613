interface Props {
    first: string;
    last: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
}

export const Address: React.FC<Props & React.HTMLAttributes<HTMLDivElement>> = (props: Props) => {
    const {first, last, address1, address2, city, state, zip} = props;
    return(
        <div {...props}>
            {first && last && <div>{first}&nbsp;{last}</div>}
            <div>{address1}{", "}{address2}</div>
            <div>{city}{", "}{state}&nbsp;{zip}</div>
        </div>
    );
}