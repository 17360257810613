import React from "react";
import { useAppDispatch, useAppSelector, useModal } from "../../hooks";
import { Order } from "../../types/Order";
import { orderService } from "../../features/order/orderService";
import { toast } from "react-toastify";
import { Spinner } from "../../components/Spinner";
import { Link } from "react-router-dom";
import { formatCurrency } from "../../utils/formatUtils";
import { Role } from "../../types/User";
import { remove } from "../../features/order/orderSlice";
import { Modal, OrderConfirmRemoveBody, ConfirmRemoveFooter } from "../../components/Modal";
import { OrderStatusBadge } from "../../components/OrderStatusBadge";

export const Orders = () => {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = React.useState(true);
    const [orders, setOrders] = React.useState<Order[]>([]);
    const [selected, setSelected] = React.useState<Order>({} as Order);
    const { user } = useAppSelector(state => state.auth);
    const [modalRef, showModal, hideModal] = useModal();

    React.useEffect(() => {
        (async () => {
            const result = await orderService.getAll(dispatch);
            if (result) {
                setOrders(result);
            } else {
                toast.error('Could not get orders');
            }
            setLoading(false);
        })();
    }, [dispatch]);

    const handleRemove = React.useCallback((id: string) => {
        setSelected(orders.find(o => o.id === id) as Order);
        showModal();
    }, [orders, showModal]);

    const handleConfirmRemove = React.useCallback(() => {
        hideModal();
        setOrders(prev => prev.filter(o => o.id !== selected.id));
        dispatch(remove(selected.id!));
        setSelected({} as Order);        
    }, [dispatch, hideModal, selected.id]);

    const sumItems = orders.map(o => o.items).flat().reduce((acc, item) => acc + item.quantity, 0);
    const sumSubtotal = orders.reduce((acc, orders) => acc + orders.subtotal, 0);
    const sumShipping = orders.reduce((acc, orders) => acc + orders.shipping, 0);
    const sumTax = orders.reduce((acc, orders) => acc + orders.tax, 0);
    const sumTotal = orders.reduce((acc, orders) => acc + orders.total, 0);
    
    return(loading ? <Spinner /> : 
        <div className="m-3">
            <button className=" btn btn-primary float-end my-3" onClick={() => console.log("Some Action")}><i className="bi bi-plus" /> Some Action</button>
            <table className="table table-sm">
                <thead>
                    <tr>
                        <th></th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Customer</th>
                        <th># of Items</th>
                        <th className="text-center">Subtotal</th>
                        <th className="text-center">Shipping</th>
                        <th className="text-center">Tax</th>
                        <th className="text-center">Total</th>
                        {user?.role === Role.Super && <th className="text-center text-danger"><i className="bi bi-trash" /></th>}
                    </tr>
                </thead>
                <tbody>
                    {orders?.length > 0 && orders.map((o, i) => 
                        <tr key={o.id} className="align-middle">
                            <td><Link to={`${o.id}`}>{i + 1}</Link></td>
                            <td>{new Date(o.createdAt!).toLocaleString()}</td>
                            <td><OrderStatusBadge status={o.status} /></td>
                            <td>{o.first} {o.last}</td>
                            <td className="text-end">{o.items?.reduce((acc, item) => acc + item.quantity, 0)}</td>
                            <td className="text-end">{formatCurrency(o.subtotal)}</td>
                            <td className="text-end">{formatCurrency(o.shipping)}</td>
                            <td className="text-end">{formatCurrency(o.tax)}</td>
                            <td className="text-end">{formatCurrency(o.total)}</td>
                            {user?.role === Role.Super && <td className="text-center">
                                <button className="btn btn-outline-danger btn-sm border-0" onClick={() => handleRemove(o.id!)}><i className="bi bi-x" /></button>
                            </td>}
                        </tr>)}
                        <tr className="table-dark">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className="text-end">{sumItems}</td>
                            <td className="text-end">{formatCurrency(sumSubtotal)}</td>
                            <td className="text-end">{formatCurrency(sumShipping)}</td>
                            <td className="text-end">{formatCurrency(sumTax)}</td>
                            <td className="text-end">{formatCurrency(sumTotal)}</td>
                            {user?.role === Role.Super && <td></td>}
                     </tr>
                </tbody>
            </table>    
            <Modal 
                id={selected.id!} 
                title={'Remove Order'} 
                ref={modalRef} 
                body={() => <OrderConfirmRemoveBody id={selected.id!} first={selected.first} last={selected.last} total={selected.total} />}
                footer={ConfirmRemoveFooter({onConfirm: handleConfirmRemove, onHide: hideModal})}
                onConfirm={handleConfirmRemove} 
                onHide={hideModal} />
        </div>);
}