import React from "react";
import { Spinner } from "../../components/Spinner";
import { useClient } from "../../hooks";
import { ClientForm } from "../../components/ClientForm";

export const ClientCreate = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [client, loading, disabled, validationResult, onChange, onAddUser, onAddComposition, _, onValidate, onSubmit] = useClient();

    return(loading ? <Spinner /> :
        <ClientForm 
            client={client} 
            disabled={disabled} 
            validationResult={validationResult} 
            onChange={onChange}
            onAddUser={onAddUser}
            onAddComposition={onAddComposition}
            onValidate={onValidate}
            onSubmit={onSubmit} />);
}