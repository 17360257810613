import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { Composition } from "../../types/Composition";
import axios from "axios";
import { logout } from "../auth/authSlice";
import { narrowError } from "../../utils/errorUtils";
import { toast } from "react-toastify";
import { getStorageItem } from "../../utils/storageUtils";
import { User } from "../../types/User";
import { ListQuery } from "../shared/Query";
import qs from "qs";

const API_URL = `${process.env.REACT_APP_API_DOMAIN}/api/compositions`;

// Add a request interceptor
axios.interceptors.request.use((config) => {
    const token = getStorageItem<User>('user')?.token;
    config.headers['Content-Type'] = 'application/json';
    config.headers["X-Auth-Token"] = token;
    config.headers["Ngrok-Skip-Browser-Warning"] = true;
    return config;
});

const getAll = async (query: ListQuery, dispatch: ThunkDispatch<unknown, unknown, AnyAction>): Promise<{compositions: Composition[], count: number} | undefined> => {
    try {
        const queryString = qs.stringify(query);
        const getUrl = `${API_URL}${queryString ? '?' + queryString : ''}`;
        const data = await axios.get(getUrl)
            .then(response => response.data)
            .catch((error) => {
                console.error(error);
                if (error.response.status === 401 || error.response.status === 403) {
                    dispatch(logout());
                }
            });

        return data;
    } catch (error) {
        const message = narrowError(error);
        toast.error(message);
        console.error('Could not get compositions', message);
    }   
}

const get = async (id: string, dispatch: ThunkDispatch<unknown, unknown, AnyAction>): Promise<Composition | undefined> => {
    try {
        const data = await axios.get(`${API_URL}/${id}`)
            .then(response => response.data)
            .catch((error) => {
                console.error(error);
                if (error.response.status === 401 || error.response.status === 403) {
                    dispatch(logout());
                }
            });

        return data;
    } catch (error) {
        const message = narrowError(error);
        toast.error(message);
        console.error('Could not get composition', message);
    }   
}

const add = async (composition: Composition, dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    try {
        const data = await axios.post(`${API_URL}`, composition)
            .then(response => response.data)
            .catch((error) => {
                console.error(error);
                if (error.response.status === 401 || error.response.status === 403) {
                    dispatch(logout());
                }
            });

        return data;
    } catch (error) {
        const message = narrowError(error);
        toast.error(message);
        console.error('Could not add composition', message);
    } 
}

const update = async (composition: Partial<Composition>, dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    try {
        const data = await axios.put(`${API_URL}/${composition.id}`, composition)
            .then(response => response.data)
            .catch((error) => {
                console.error(error);
                if (error.response.status === 401 || error.response.status === 403) {
                    dispatch(logout());
                }
            });

        return data;
    } catch (error) {
        const message = narrowError(error);
        toast.error(message);
        console.error('Could not update composition', message);
    } 
}

const remove = async (id: string, dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    try {
        const data = await axios.delete(`${API_URL}/${id}`)
            .then(response => response.data)
            .catch((error) => {
                console.error(error);
                if (error.response.status === 401 || error.response.status === 403) {
                    dispatch(logout());
                }
            });

        return data;
    } catch (error) {
        const message = narrowError(error);
        toast.error(message);
        console.error('Could not remove composition', message);
    } 
}

export const compositionService = { getAll, get, add, update, remove };