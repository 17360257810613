import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Guest } from "../../types/Guest";

interface GuestState extends Guest {}

const initialState: GuestState = {
    id: undefined,
    first: '',
    last: '',
    email: '',
    phone: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    subscribed: false
}

export const guestSlice = createSlice({
    name: 'guest',
    initialState: initialState,
    reducers: {
        update: (state, action: PayloadAction<Partial<Guest>>) => {
            state = {...state, ...action.payload};            
            return state;
        },
        reset: (state) => {
            state = initialState;
            return state;
        }
    }
});
  
export const { update, reset } = guestSlice.actions;
export default guestSlice.reducer;