import React from "react";
import { Link, useParams } from "react-router-dom";
import { orderService } from "../../features/order/orderService";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Order, Status } from "../../types/Order";
import { Spinner } from "../../components/Spinner";
import { Address } from "../../components/Address";
import { formatCurrency } from "../../utils/formatUtils";

export const OrderDetails = () => {
    const dispatch = useDispatch();
    const { id } = useParams<{id: string}>();
    const [loading, setLoading] = React.useState(true);
    const [order, setOrder] = React.useState<Order>({} as Order);    
    const {first, last, address1, address2, city, state, zip, phone, email, items, subtotal, shipping, tax, total, paymentIntent} = order;

    React.useEffect(() => {
        if (id) {
            (async () => {
                const result = await orderService.get(id, dispatch);
                if (result) {
                    setOrder(result as Order);
                } else {
                    toast.error('Could not find order ' + id);
                }
                
            })();
            setLoading(false);
        }
    }, [dispatch, id]);

    const handleStatusUpdate = React.useCallback(async (e: React.ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault();
        setLoading(true);
        const {name, value} = e.target;
        const result = await orderService.update({[name]: value, id}, dispatch);
        if (result) {
            setOrder(result);
        }

        setLoading(false);
    }, [dispatch, id]);

    return(loading ? <Spinner /> : 
        <div className="d-flex flex-column">
            <div className="d-flex align-items-center bg-white shadow-sm p-3 m-3 rounded ">
                <strong>Order #</strong>
                <span className="ms-1">{order.id}</span>
                <div className="d-flex align-items-center ms-auto">
                    <strong className="me-2">Status</strong>
                    <select className="form-select form-select-sm" name="status" value={order.status} onChange={handleStatusUpdate}>
                        {Object.values(Status).map(s => <option key={s} value={s}>{s}</option>)}
                    </select>
                </div>
            </div>
            <div className="d-flex flex-column bg-white shadow-sm p-3 m-3 rounded">
                <strong>Shipping</strong>
                <Address first={first} last={last} address1={address1} address2={address2} city={city} state={state} zip={zip} />
                <div>{phone}</div>
                <div>{email}</div>
            </div>
            <div className="d-flex flex-column bg-white shadow-sm p-3 m-3 rounded">
                <strong>Items</strong>
                <table>
                    <tbody>
                        {items?.length > 0 && items.map((item, i) => 
                            <tr key={`${i}-${item.id}`}>
                                {item.images && item.images.length > 0 && <td><img width={50} src={item.images[0].src} alt={item.images[0].alt} className="img-fluid hover-zoom" /></td>}
                                <td><Link to={`/admin/compositions/${item.id}`}>{item.title}</Link></td>
                                <td><span>Qty: {item.quantity}</span></td>
                                {item.price && <td className="text-end"><span>{formatCurrency(item.price)}</span></td>}
                            </tr>)}
                    </tbody>
                </table>
            </div>
            <div className="d-flex flex-column bg-white shadow-sm p-3 m-3 rounded">
                <strong>Totals</strong>
                <table>
                    <tbody>
                        <tr>
                            <td>Subtotal</td>
                            <td className="text-end">{formatCurrency(subtotal)}</td>
                        </tr>
                        <tr>
                            <td>Shipping</td>
                            <td className="text-end">{formatCurrency(shipping)}</td>
                        </tr>
                        <tr>
                            <td>Tax</td>
                            <td className="text-end">{formatCurrency(tax)}</td>
                        </tr>
                        <tr>
                            <td>Total</td>
                            <td className="text-end">{formatCurrency(total)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="d-flex flex-column bg-white shadow-sm p-3 m-3 rounded">
                <strong>Stripe Details</strong>
                <div>Payment Intent: <Link to={`https://dashboard.stripe.com/test/payments/${paymentIntent}`} target="_blank" rel="noopener noreferrer">{paymentIntent}</Link></div> {/* TODO: Update test route*/}
            </div>          
        </div>);
}