import React from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import { remove, update } from "../features/cart/cartSlice";
import { CartItem } from "../types/CartItem";
import { useNavigate } from "react-router-dom";
import { formatCurrency } from "../utils/formatUtils";

export const Cart = () => {
    const { items } = useAppSelector(state => state.cart);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleUpdate = React.useCallback((item: CartItem, diff: number) => {
        dispatch(update({ ...item, quantity: item.quantity + diff }));
    }, [dispatch]);

    const handleRemove = React.useCallback((id: string) => {
        dispatch(remove(id));
    }, [dispatch]);

    const handleCompositionNavigate = React.useCallback((id: string) => {
        navigate(`/compositions/${id}`);
    }, [navigate]);

    return(
        <div className="offcanvas offcanvas-end" tabIndex={-1} id="cartOffCanvasRight">
            <div className="offcanvas-header ms-auto">
                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">                
                {items.length > 0
                    ? <div className="d-flex flex-column h-100 pb-3">
                        {items.map(item => 
                            <div key={item.id} className="d-flex flex-column align-items-center my-2">
                                <img src={item.images[0].src} className="img-fluid" alt={item.images[0].alt} style={{maxWidth: 250}} data-bs-dismiss="offcanvas" onClick={() => handleCompositionNavigate(item.id)} />
                                <small data-bs-dismiss="offcanvas" onClick={() => handleCompositionNavigate(item.id)}>{item.title.toUpperCase()}</small>
                                <small>{formatCurrency(item.price)}</small>
                                <div className="btn-group">
                                    <button type="button" className="btn btn-outline-dark border-0" disabled={item.quantity === 1} onClick={() => handleUpdate(item, -1)}><i className="bi bi-dash" /></button>
                                    <div className="align-middle py-2 px-3">{item.quantity}</div>
                                    <button type="button" className="btn btn-outline-dark border-0" disabled={item.quantity === 10} onClick={() => handleUpdate(item, 1)}><i className="bi bi-plus" /></button>
                                </div>
                                <small className="text-muted" style={{cursor: 'pointer'}} onClick={() => handleRemove(item.id)}>REMOVE</small>
                            </div>)}
                        <button className="btn btn-outline-dark rounded-0 border-0 px-3 py-2 mt-auto" data-bs-dismiss="offcanvas" onClick={() => navigate('/checkout')}>CHECKOUT</button>
                    </div>
                    : <div className="d-flex flex-column justify-content-center align-items-center h-100">
                        <span>YOUR CART IS EMPTY</span>
                        <span>START SHOPPING</span>
                    </div>}
            </div>
        </div>);
}