import React from "react";
import { Composition } from "../types/Composition";
import { ImagePreview } from "./ImagePreview";
import { ValidationResult } from "../types/ValidationResult";
import { Dev } from "./Dev";
import { useAppSelector } from "../hooks";
import { Role } from "../types/User";

interface CompositionFormProps {
    composition: Composition;
    disabled: boolean;
    validationResult: ValidationResult<Composition>;
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onCheckedChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onAddImage: () => void;
    onRemoveImage: (sort: number) => void;
    onValidate: (composition?: Composition) => boolean;
    onSubmit: (composition: Composition) => Promise<void>;
    onFake?: (clientId: string) => void;
}

export const CompositionForm = ({composition, disabled, validationResult, onChange, onCheckedChange, onAddImage, onRemoveImage, onValidate, onSubmit, onFake, ...props}: CompositionFormProps) => {
    const {user} = useAppSelector(state => state.auth);
    const {clientId, title, description, materials, images, price, width, height, year, isActive, isUnique} = composition;

    const handleSubmit = React.useCallback(async (composition?: Composition) => {
        const valid = onValidate(composition); // need to know if valid immediately
        
        if (valid) {
            await onSubmit(composition!);
        }
    }, [onSubmit, onValidate]);

    const compositionImage = React.useCallback((sort: number) => composition?.images?.find(x => x.sort === sort), [composition?.images]);
    const compositionImageValid = React.useCallback((sort: number) => {
        const image = compositionImage(sort);
        return image?.src && image?.alt;
    }, [compositionImage]);

    return(
        <div className="d-flex bg-white rounded shadow p-3">
            <div className="d-flex w-50 justify-content-center p-2">
                <ImagePreview images={images} /> 
            </div>
            <div className="d-flex flex-column w-50 p-2">
                <div className="d-flex align-items-center mb-3">
                    {user?.role === Role.Super && <div className="w-50">
                        <small className="form-label">Client</small>
                        <input type="text" name="clientId" disabled className="form-control form-control-sm" value={clientId} />
                    </div>}
                    <div className="form-check form-switch ms-auto">
                        <input className="form-check-input" name="isActive" type="checkbox" checked={isActive} onChange={onCheckedChange} />
                        <label className="form-check-label">Active</label>
                    </div>
                </div>
                <div className="d-flex mb-3">
                    <div className="w-100">
                        <small className="form-label">Title</small>
                        <input type="text" name="title" className={`form-control form-control-sm ${validationResult.errors.title ? 'is-invalid' : null}`} value={title} onChange={onChange} />
                        <small className="text-danger">{validationResult.errors.title}</small>
                    </div>
                </div>
                <div className="d-flex mb-3">
                    <div className="w-100">
                        <small className="form-label">Description</small>
                        <textarea name="description" className={`form-control form-control-sm ${validationResult.errors.description ? 'is-invalid' : null}`} rows={3} value={description} onChange={onChange} />
                        <small className="text-danger">{validationResult.errors.description}</small>
                    </div>
                </div>
                <div className="d-flex mb-3">
                    <div className="w-100">
                        <small className="form-label">Materials</small>
                        <input type="text" name="materials" className={`form-control form-control-sm ${validationResult.errors.materials ? 'is-invalid' : null}`} value={materials} onChange={onChange} />
                        <small className="text-danger">{validationResult.errors.materials}</small>
                    </div>
                </div>
                <div className="d-flex mb-3">
                    <div className="form-check form-switch">
                        <input className="form-check-input" name="isUnique" type="checkbox" checked={isUnique} onChange={onCheckedChange} />
                        <label className="form-check-label">Unique</label>
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    <small className="form-label">Images</small>
                    <button className="btn btn-primary btn-sm ms-auto" disabled={!compositionImageValid(1)} onClick={onAddImage}><i className="bi bi-plus" /></button>
                </div>
                <div className="d-flex">
                    <div className="w-75 me-3">
                        <small className="form-label">Source URL</small>
                    </div>
                    <div className="w-25">
                        <small className="form-label">Alt Text</small>
                    </div>
                </div>
                {images?.length > 0 && images?.map(i => 
                    <div key={`image-${i.sort}`} className="d-flex mb-2">
                        <div className="w-75 me-3">
                            <div className="input-group input-group-sm">
                                <span className="input-group-text">{i.sort}</span>
                                <input type="text" className="form-control form-control-sm" data-sort={i.sort} name="src" value={compositionImage(i.sort)?.src} onChange={onChange} />
                            </div>
                        </div>
                        <div className="w-25">
                            <input type="text" className="form-control form-control-sm" data-sort={i.sort} name="alt" value={compositionImage(i.sort)?.alt} onChange={onChange} />
                        </div>
                        {i.sort !== 1 && <button className="btn btn-outline-danger btn-sm border-0 ms-1" onClick={() => onRemoveImage(i.sort)}><i className="bi-x" /></button>}
                    </div>)}
                <div className="d-flex mb-3">
                    <div className="w-25 me-2">
                        <small className="form-label">Width</small>
                        <input type="text" name="width" className={`form-control form-control-sm ${validationResult.errors.width ? 'is-invalid' : null}`} value={width} onChange={onChange} />
                        <small className="text-danger">{validationResult.errors.width}</small>
                    </div>
                    <div className="w-25 me-2">
                        <small className="form-label">Height</small>
                        <input type="text" name="height" className={`form-control form-control-sm ${validationResult.errors.height ? 'is-invalid' : null}`} value={height} onChange={onChange} />
                        <small className="text-danger">{validationResult.errors.height}</small>
                    </div>
                    <div className="w-25 me-2">
                        <small className="form-label">Year</small>
                        <input type="text" name="year" className={`form-control form-control-sm ${validationResult.errors.year ? 'is-invalid' : null}`} value={year} onChange={onChange} />
                        <small className="text-danger">{validationResult.errors.year}</small>
                    </div>
                    <div className="w-25">
                        <small className="form-label">Price</small>
                        <div className="input-group input-group-sm">
                            <span className="input-group-text">$</span>
                            <input type="text" className={`rounded-end form-control form-control-sm ${validationResult.errors.price ? 'is-invalid' : null}`} name="price" value={price} onChange={onChange} />
                            <div className="text-danger">{validationResult.errors.price}</div>
                        </div>
                    </div>
                </div>
                <div className="d-flex align-items-center mt-auto">
                    {onFake && <Dev className="d-flex align-items-center"><button onClick={() => onFake(clientId)}>Use Fake Data</button></Dev>}
                    <button className="btn btn-primary ms-auto" disabled={disabled} onClick={() => handleSubmit(composition)}>Submit</button>
                </div>
            </div>
        </div>);
}