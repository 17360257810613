import React from "react";
import { guestService } from "../features/guest/guestService";
import { toast } from "react-toastify";
import { Message, validateMessage, validateMessageField } from "../types/Message";
import { ValidationResult } from "../types/ValidationResult";

export const Contact = () => {
    const initState = {email: '', body: ''} as Message;
    const [message, setMessage] = React.useState(initState);
    const [validationResult, setValidationResult] = React.useState<ValidationResult<Message>>({valid: false, errors: {id: '', email: '', body: ''}});

    const handleChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        e.preventDefault();
        const { name, value } = e.target;
        const error = validateMessageField({name, value});
        setValidationResult((prev: ValidationResult<Message>) => ({...prev, errors: {...prev.errors, [name]: error}}));
        setMessage(prev => ({...prev, ...{[name]: value}}));
    }, []);

    const handleSubmit = async () => {
        const {valid, errors} = validateMessage(message);
        setValidationResult({valid, errors});
        if (valid) {
            const result = await guestService.send(message);
            if (result.id) {
                toast.success(`Message sent`);
                setMessage(initState);
            }
        }      
    }

    return(
        <div className="d-flex flex-column align-items-center justify-content-center w-100">
            <div className="mb-3 w-50">
                <label>Email</label>
                <input type="text" className={`form-control stripe-input ${validationResult.errors.email ? 'is-invalid' : null}`} value={message.email} name="email" onChange={handleChange} />
                <small className="text-danger">{validationResult.errors.email}</small>
            </div>
            <div className="mb-3 w-50">
                <label>Message</label>
                <textarea className={`form-control stripe-input ${validationResult.errors.body ? 'is-invalid' : null}`} rows={3} value={message.body} name="body" onChange={handleChange} />
                <small className="text-danger">{validationResult.errors.body}</small>
            </div>
            <button type="button" className="btn btn-outline-dark rounded-0 py-2 px-3" onClick={handleSubmit}>SEND</button>
        </div>);
}