import axios from "axios";
import { removeStorageItem, setStorageItem } from "../../utils/storageUtils";

const API_URL = `${process.env.REACT_APP_API_DOMAIN}/api/users`;

// Add a request interceptor
axios.interceptors.request.use((config) => {
    config.headers['Content-Type'] = 'application/json';
    config.headers["Ngrok-Skip-Browser-Warning"] = true;
    return config;
});

const register = async ({ email, password, first, last }: { email: string, password: string, first: string, last: string }) => {    
    const { data } = await axios.post(`${API_URL}/register`, { email, password, first, last });

    if (data) {
        setStorageItem('user', data);
    }

    return data;
}

const login = async ({ email, password }: { email: string, password: string }) => {
    const { data } = await axios.post(`${API_URL}/login`, { email, password });

    if (data) {
        setStorageItem('user', data);
    }

    return data;
}

const logout = () => removeStorageItem('user');

export const authService = { register, login, logout };