import { Cart } from "./Cart";
import { Guest } from "./Guest";

export interface Order extends Guest, Cart {
    id: string | undefined;
    number: string;
    status: Status;
    paymentIntent: string | undefined;
    error: string | undefined;
    createdAt: string | undefined;
}

export enum Status {
    Unknown = 'Unknown',
    New = 'New',
    InProgress = 'In Progress',
    Shipped = 'Shipped',
    Delivered = 'Delivered',
    Completed = 'Completed',
}