import axios from "axios";
import qs from "qs";
import { getStorageItem } from "../../utils/storageUtils";
import { User } from "../../types/User";
import { narrowError } from "../../utils/errorUtils";
import { toast } from "react-toastify";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { logout } from "../auth/authSlice";
import { Invoice, LineItem } from "../../types/Invoice";
import { BusinessEntity } from "../../types/BusinessEntity";
import { ListQuery } from "../shared/Query";

const API_URL = `${process.env.REACT_APP_API_DOMAIN}/api/invoices`;
axios.interceptors.request.use((config) => {
    const token = getStorageItem<User>('user')?.token;
    config.headers['Content-Type'] = 'application/json';
    config.headers["X-Auth-Token"] = token;
    config.headers["Ngrok-Skip-Browser-Warning"] = true;
    return config;
});

const getAll = async (query: ListQuery, dispatch: ThunkDispatch<unknown, unknown, AnyAction>): Promise<{invoices: Invoice[], count: number} | undefined> => {
    try {
        const queryString = qs.stringify(query);
        const getUrl = `${API_URL}${queryString ? '?' + queryString : ''}`;
        const data = await axios
            .get(getUrl)
            .then(response => response.data)
            .catch((error) => {
                console.error(error);
                if (error.response.status === 401 || error.response.status === 403) {
                    dispatch(logout());
                }
            });
        return data;
    } catch (error) {
        const message = narrowError(error);
        toast.error(message);
        console.error('Could not get invoices', message);
    }   
}

const get = async (id: string, dispatch: ThunkDispatch<unknown, unknown, AnyAction>): Promise<Invoice | undefined> => {
    try {
        const data = await axios
            .get(`${API_URL}/${id}`)
            .then(response => response.data)
            .catch((error) => {
                console.error(error);
                if (error.response.status === 401 || error.response.status === 403) {
                    dispatch(logout());
                }
            });
        return data;
    } catch (error) {
        const message = narrowError(error);
        toast.error(message);
        console.error('Could not get invoice', message);
    }   
}

const add = async (invoice: {to: BusinessEntity, lineItems: LineItem[]}, dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    try {
        const data = await axios.post(`${API_URL}`, invoice)
            .then(response => response.data)
            .catch((error) => {
                console.error(error);
                if (error.response.status === 401 || error.response.status === 403) {
                    dispatch(logout());
                }
            });
        return data;
    } catch (error) {
        const message = narrowError(error);
        toast.error(message);
        console.error('Could not add invoice', message);
    }
}

const update = async (invoice: Partial<Invoice>, dispatch: ThunkDispatch<unknown, unknown, AnyAction>): Promise<Invoice | undefined> => {    
    try {
        const data = await axios.put(`${API_URL}/${invoice.id}`, invoice)
            .then(response => response.data)
            .catch((error) => {
                console.error(error);
                if (error.response.status === 401 || error.response.status === 403) {
                    dispatch(logout());
                }
            });
        return data;
    } catch (error) {
        const message = narrowError(error);
        toast.error(message);
        console.error('Could not update invoice', message);
    }
}

const send = async (id: string) => {
    try {
        const data = await axios.get(`${API_URL}/${id}/send`);
        return data;
    } catch (error) {
        const message = narrowError(error);
        toast.error(message);
        console.error('Could not send invoice', message);
    }
}

const remove = async (id: string, dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    try {
        const data = await axios.delete(`${API_URL}/${id}`)
            .then(response => response.data)
            .catch((error) => {
                console.error(error);
                if (error.response.status === 401 || error.response.status === 403) {
                    dispatch(logout());
                }
            });

        return data;
    } catch (error) {
        const message = narrowError(error);
        toast.error(message);
        console.error('Could not remove invoice', message);
    } 
}

export const invoiceService = { getAll, get, add, update, send, remove };