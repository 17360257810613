import React from "react";
import { useParams } from "react-router-dom";
import { Spinner } from "../../components/Spinner";
import { useUser } from "../../hooks";
import { UserForm } from "../../components/UserForm";

export const User = () => {    
    const { id } = useParams<{id: string}>();
    const [user, loading, disabled, validationResult, onChange, onValidate, onSubmit] = useUser(id);

    return(loading ? <Spinner /> :
        <UserForm 
            user={user} 
            disabled={disabled} 
            validationResult={validationResult} 
            onChange={onChange}
            onValidate={onValidate}
            onSubmit={onSubmit} />);
}