import axios from "axios";
import { toast } from "react-toastify";
import { narrowError } from "../../utils/errorUtils";

const API_URL = `${process.env.REACT_APP_API_DOMAIN}/api/payments`;
axios.interceptors.request.use((config) => {
    config.headers['Content-Type'] = 'application/json';
    config.headers["Ngrok-Skip-Browser-Warning"] = true;
    return config;
});

const config = async () => {    
    try {
        const { data } = await axios.get(`${API_URL}/config`);
        return data;
    } catch (error) {
        const message = narrowError(error);
        toast.error(message);
        console.error('Could not add order', message);
    }
}

const createPaymentIntent = async (amount: number) => {
    try {
        const { data } = await axios.post(`${API_URL}/createPaymentIntent`, {amount});
        return data;
    } catch (error) {
        const message = narrowError(error);
        toast.error(message);
        console.error('Could not add order', message);
    }
} 

export const stripeService = { config, createPaymentIntent };