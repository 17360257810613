import React from "react";
import { Image } from '../types/Image';

export const ImagePreview = ({images, maxHeight}: {images: Image[], maxHeight?: number}) => {
    const anyImages = images.length > 0 && images.some(i => i.src);
    const init = images.find(i => i.sort === 1) as Image;
    const [current, setCurrent] = React.useState<Image>(init);

    React.useEffect(() => {
        setCurrent(init);
    }, [images, init]);

    const handleClick = React.useCallback((sort: number) => {
        setCurrent(images.find(i => i.sort === sort) as Image);
    }, [images]);

    return(
        <div className="d-flex flex-column justify-content-center">
            {anyImages 
                ? <>
                    <img src={current.src} className="img-fluid" alt={current.alt} style={{maxHeight: maxHeight || 500}} />
                    <div className="mt-2">
                        {images.map(i => <img key={`preview-image-${i.sort}`} className="my-1" src={i.src} alt={i.alt} width='100' onClick={() => handleClick(i.sort)} />)}
                    </div>
                </>
                : <div className="d-flex align-items-center justify-content-center" style={{height: 500, width: 500, backgroundColor: '#e9ecef'}}><h3 className="text-white">AB</h3></div>}          
        </div>);
}