import React from "react";
import { useParams } from "react-router-dom";
import { Spinner } from "../../components/Spinner";
import { InvoiceForm } from "../../components/InvoiceForm";
import { useInvoice } from "../../hooks";

export const Invoice = () => {
    const { id } = useParams<{id: string}>();
    const [invoice, loading, disabled, validationResult, onAddLineItem, onRemoveLineItem, onChange, onStatusChange, onDueDateChange, onBusinessEntityChange, onLineItemChange, onNotesChange, onValidate, onSubmit, onSend, onFake] = useInvoice(id);

    return(loading ? <Spinner /> : 
        <InvoiceForm 
            invoice={invoice} 
            disabled={disabled} 
            validationResult={validationResult}
            onAddLineItem={onAddLineItem}
            onRemoveLineItem={onRemoveLineItem}
            onChange={onChange}
            onStatusChange={onStatusChange}
            onDueDateChange={onDueDateChange}
            onBusinessEntityChange={onBusinessEntityChange}
            onLineItemChange={onLineItemChange}
            onNotesChange={onNotesChange}
            onValidate={onValidate}
            onSubmit={onSubmit}
            onSend={onSend}
            onFake={onFake} />);
}