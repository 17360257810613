import {Image } from './Image';
import { ValidationResult } from './ValidationResult';

export interface Composition {
    id: string;
    clientId: string;
    title: string;
    description: string;
    materials: string;
    images: Image[];
    price: number;
    width: number;
    height: number;
    year: number;
    isActive: boolean;
    isUnique: boolean;
}

export function isComposition(object: any | undefined): object is Composition {
    return object && 'clientId' in object 
        && 'title' in object 
        && 'description' in object
        && 'materials' in object 
        && 'images' in object 
        && 'price' in object 
        && 'width' in object 
        && 'height' in object 
        && 'year' in object;
}

export function initComposition(clientId: string): Composition {
    const initImages = [{id: '', src: '', alt: '', sort: 1}];
    const initComposition = {id: '', clientId, title: '', description: '', materials: '', images: initImages, price: 0, width: 0, height: 0, year: 0, isUnique: true, isActive: false};

    return initComposition;
}

export function validateComposition(composition?: Partial<Composition>) {
    const validationResult: ValidationResult<Composition> = {
        valid: false,
        errors: {id: '', clientId: '', title: '', description: '', materials: '', images: '', price: '', width: '', height: '', year: '', isUnique: '', isActive: ''}
    };

    if (!composition || Object.entries(composition).length === 0) {
        for (const key in validationResult.errors) {
            (validationResult.errors as any)[key] = validateCompositionField({key, value: undefined});
        }    
        return validationResult;
    }

    let key: keyof Composition;
    for (key in composition) {
        const value = composition ? composition[key] : undefined;
        validationResult.errors[key] = validateCompositionField({key, value});
    }    

    // no errors, validate
    if (Object.entries(validationResult.errors).every(e => !e[1])) {
        validationResult.valid = true;
    }

    return validationResult; 
}

export function validateCompositionField({key, value}: {[key: string]: string | number | boolean | Image[] | undefined}): string {
    let error = '';

    switch (key) {
        case 'title':
            if (!value) {
                error = 'Title is required';
            }
            break;
        case 'description':
            if (!value) {
                error = 'Description is required';
            }
            break;
        case 'materials':
            if (!value) {
                error = 'Materials is required';
            }
            break;
        case 'images':
            if (!value || (Array.isArray(value) && value.length === 0)) {
                error = 'At least one image is required';
            }
            break;
        case 'price':
            if (!value) {
                error = 'Price is required';
            } else if (Number(value) === 0) {
                error = 'Price cannot be zero';
            }
            break;
        case 'width':
            if (!value) {
                error = 'Width is required';
            } else if (Number(value) === 0) {
                error = 'Width cannot be zero';
            }
            break;
        case 'height':
            if (!value) {
                error = 'Height is required';
            } else if (Number(value) === 0) {
                error = 'Height cannot be zero';
            }
            break;
        case 'year':
            if (!value) {
                error = 'Year is required';
            } else if (Number(value) === 0) {
                error = 'Year cannot be zero';
            }
            break;
        default:
            break;
    }
    if(error) console.log('Composition validation error', error);
    return error;
}