import { Status } from "../types/Order"

export const OrderStatusBadge = ({status}: {status: Status}) => {
    let variant = 'secondary';
    switch (status) {
        case Status.New:
            variant = 'danger';
            break;
        case Status.InProgress:
            variant = 'primary';
            break;
        case Status.Shipped:
            variant = 'warning';
            break;
        case Status.Delivered:
            variant = 'info';
            break;
        case Status.Completed:
            variant = 'success';
            break;
        case Status.Unknown:
        default:
            break;
    }

    return(<span className={`badge bg-${variant}`}>{status}</span>);
}