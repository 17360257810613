import { Address, validateAddressField } from "./Address";
import { ValidationResult } from "./ValidationResult";

export interface BusinessEntity extends Address {
    id: string | undefined;
    name: string;
    first: string;
    last: string;
    phone: string;
    email: string;
    website: string;
}

export const initValidationResult: ValidationResult<BusinessEntity> = {
    valid: false,
    errors: {
        id: '',
        name: '',
        first: '',
        last: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        email: '',
        website: ''
    }
};

export function validateBusinessEntity(be?: Partial<BusinessEntity>) {
    const validationResult = { // If initValidationResult is used 'valid' is reset to true. WTF?
        valid: false,
        errors: {
            id: '',
            name: '',
            first: '',
            last: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
            phone: '',
            email: '',
            website: ''
        }
    };

    if (!be || Object.entries(be).length === 0) {
        for (const key in validationResult.errors) {
            // itemize errors by key to know exactly what is missing
            (validationResult.errors as any)[key] = validateBusinessEntityField({key, value: undefined});
        }    
        return validationResult;
    }

    let key: keyof BusinessEntity;
    for (key in be) {
        const value = be ? be[key] : undefined;
        validationResult.errors[key] = validateBusinessEntityField({key, value});
    }    

    // no errors, validate
    if (Object.entries(validationResult.errors).every(e => !e[1])) {
        validationResult.valid = true;
    }

    return validationResult; 
}

export function validateBusinessEntityField({key, value}: {[key: string]: string | Address | undefined}): string {
    let error = '';

    switch (key) {
        case 'name':
            if (!value) {
                error = 'Company name is required';
            }
            break;
        case 'first':
            if (!value) {
                error = 'Contact first name is required';
            }
            break;
        case 'last':
            if (!value) {
                error = 'Contact last name is required';
            }
            break;
        case 'address1':
        case 'address2':
        case 'city':
        case 'state':
        case 'zip':
            error = validateAddressField({key, value});
            break;
        case 'phone':
            if (!value) {
                error = 'Phone is required';
            }
            break;
        case 'email':
            if (!value) {
                error = 'Email is required';
            }
            break;
        case 'website':     
        default:
            break;
    }
    if(error) console.log('Business entity validation error', error);
    return error;
}