import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { narrowError } from "../../utils/errorUtils";
import { orderService } from "./orderService";
import { BaseState } from "../shared/BaseState";
import { Order, Status } from "../../types/Order";
import { toast } from "react-toastify";

interface OrderState extends Omit<BaseState, 'error'>, Order {}

const initialState: OrderState = {    
    loading: false,
    success: false,
    error: undefined,
    id: '',
    number: '',
    status: Status.New,
    first: '', 
    last: '', 
    email: '', 
    phone: '', 
    address1: '', 
    address2: '', 
    city: '', 
    state: '', 
    zip: '',
    items: [],
    subtotal: 0,
    shipping: 0,
    tax: 0,
    total: 0,
    paymentIntent: undefined,
    createdAt: undefined,
    subscribed: false
}

// Add order
export const add = createAsyncThunk(
    'order/add',
    async(order: Order, thunkAPI) => {
        try {
            const result = await orderService.add(order);
            await thunkAPI.dispatch(update(result));
        } catch (error) {
            const message = narrowError(error);
            toast.error(message);
            return thunkAPI.rejectWithValue(message);
        }
    });

// Update order
// export const updateOrder = createAsyncThunk(
//     "order/update",
//     async (order: Partial<Order>, thunkAPI) => {
//         try {
//             const updated = await orderService.update(order, thunkAPI.dispatch);
//             if (updated?.id) {
//                 toast.success(`Order ${updated.id} updated`);
//             } else {
//                 toast.error(updated?.message);
//             }
//             return updated;
//         } catch (error) {
//             const message = narrowError(error);
//             toast.error(message);
//             return thunkAPI.rejectWithValue(message);
//         }
//     });

// Remove order
export const remove = createAsyncThunk(
    "order/remove",
    async (id: string, thunkAPI) => {
        try {
            const removed = await orderService.remove(id, thunkAPI.dispatch);
            if (removed?.id) {
                toast.success(`Order ${removed.id} removed`);
            } else {
                toast.error(removed?.message);
            }
            return removed;
        } catch (error) {
            const message = narrowError(error);
            toast.error(message);
            return thunkAPI.rejectWithValue(message);
        }
    });

export const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        update: (state, action: PayloadAction<Partial<Order>>) => {
            return {...state, ...action.payload};
        },
        reset: (state) => {
            state = initialState;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(add.pending, (state) => { state.loading = true })
            .addCase(add.fulfilled, (state, action: PayloadAction<any>) => {
                return {...state, loading: false, error: undefined};
            })
            .addCase(add.rejected, (state, action: PayloadAction<any>) => { 
                return {...state, loading: false, error: action.payload};
            })
            // .addCase(updateOrder.pending, (state) => { state.loading = true })
            // .addCase(updateOrder.fulfilled, (state, action: PayloadAction<any>) => {
            //     return {...state, loading: false, error: undefined};
            // })
            // .addCase(updateOrder.rejected, (state, action: PayloadAction<any>) => { 
            //     return {...state, loading: false, error: action.payload};
            // })
            .addCase(remove.pending, (state) => { state.loading = true; })
            .addCase(remove.fulfilled, (state, action: PayloadAction<Order | undefined>) =>{
                return {...state, loading: false, success: !!action.payload?.id};
            })
            .addCase(remove.rejected, (state, action) => {
                return {...state, loading: false, success: false, error: narrowError(action.payload)};
            })
    }
});

export const { reset, update } = orderSlice.actions;
export default orderSlice.reducer;