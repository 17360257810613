import { useNavigate } from "react-router-dom";
import React from "react";
import { Spinner } from "../components/Spinner";
import { toast } from "react-toastify";
import { orderService } from "../features/order/orderService";
import { Order } from "../types/Order";

export const Confirmation = () => {
    const [loading, setLoading] = React.useState(true);
    const [order, setOrder] = React.useState<Partial<Order>>();
    const navigate = useNavigate();
    const id = new URLSearchParams(window.location.search).get('id');

    React.useEffect(() => {
        if (id) {
            (async () => {
                const result = await orderService.confirm(id);
                if (result) {
                    setOrder(result);
                } else {
                    toast.error('Could not confirm order');
                }
                setLoading(false);          
            })();
        } else {
            navigate('/');
        }
    }, [id, navigate]);

    return(loading
        ? <Spinner /> 
        : id && order
            ? <Success number={order.number!} />
            : <Fail />);
}

const Success = ({number}: {number: string}) => {
    return(
        <div className="d-flex flex-column m-xl-5 m-lg-4 m-md-3 m-1 p-xl-5 p-lg-4 p-md-3 p-1">
            <b className="fs-3 mb-3">THANK YOU FOR YOUR ORDER</b>
            <span>An email confirmation for order <b>{number}</b> will be sent to you shortly.</span>
        </div>);
}

const Fail = () => {
    return(
        <div className="d-flex flex-column m-xl-5 m-lg-4 m-md-3 m-1 p-xl-5 p-lg-4 p-md-3 p-1">
            <b className="fs-3 mb-3">SORRY! SOMETHING WENT WRONG</b>
            <span>Please contact customer support</span>
            <span>1 (800) 123 4567</span>
        </div>);
}