import { CartItem } from "./CartItem";

export interface Cart {
    items: CartItem[];
    subtotal: number;
    shipping: number;
    tax: number;
    total: number;
}

export const calcSubtotal = (items: CartItem[]): number => items.reduce((acc: number, item: CartItem) => acc + item.quantity * item.price, 0);
export const calcTax = (subtotal: number): number => subtotal * 0.0775; // CA tax rate
export const calcTotal = (subtotal: number, shipping: number, tax: number): number => subtotal + shipping + tax;

export const recalculateTotals = (items: CartItem[], shipping: number): {} => {
    const subtotal = calcSubtotal(items);
    const tax = calcTax(subtotal);
    const total = calcTotal(subtotal, shipping, tax);
    return {subtotal, tax, total};
}