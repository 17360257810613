import React from "react";
import { useAppDispatch, useAppSelector, useCollapse, useDropdown } from "../hooks";
import { logout } from "../features/auth/authSlice";
import { Link } from "react-router-dom";
import { Role } from "../types/User";

export const TopNav = () => {
    const { user } = useAppSelector(state => state.auth);
    const { items } = useAppSelector(state => state.cart);
    const dispatch = useAppDispatch();
    const [navRef, onToggle] = useCollapse();
    const [dropdownRef, onDropdownToggle] = useDropdown();
    const [hasBackground, setHasBackground] = React.useState(false);
    const [navCollapsed, setNavCollapsed] = React.useState(true);
    const itemsTotal = items.reduce((acc, i) => acc + i.quantity, 0);

    React.useEffect(() => {
        const handleScroll = () => {
            const show = window.scrollY > 75;
            if (navRef.current) {
                setHasBackground(show);
            }
        }
        document.addEventListener('scroll', handleScroll);
        
        return () => {
            document.removeEventListener('scroll', handleScroll);
        }
    }, [navRef]);
    
    const handleLogout = React.useCallback(() => {
        dispatch(logout());
    }, [dispatch]);

    const handleHamburgerClick = React.useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        e.currentTarget.blur();
        onToggle();
        setNavCollapsed(!navCollapsed);
    }, [onToggle]);
    
    return(
        <nav className="navbar sticky-top navbar-light" style={{ backgroundColor: (!navCollapsed || hasBackground) ? 'white' : 'transparent'}}>
            <div className="container-fluid d-flex justify-content-between mx-2"> {/* https://stackoverflow.com/questions/60694918/how-to-change-bootstrap-navbar-color-on-scroll-in-react-js */}
                <button className="navbar-toggler border-0" type="button" onClick={handleHamburgerClick}>
                    <span className="navbar-toggler-icon"></span>
                </button>
                <span className="display-5 mx-auto">ALYSSA BREID</span>
                {/* Visible on md + screens */}
                <div className="navbar-nav d-none d-md-flex flex-row"> {/* https://stackoverflow.com/questions/35351353/missing-visible-and-hidden-in-bootstrap */}
                    {!!user?.token 
                        ? <UserDropdown ref={dropdownRef} onToggle={onDropdownToggle} onLogout={handleLogout} />
                        : <div className="nav-item">
                            <Link className="nav-link" to="/login" onClick={onToggle}>LOGIN</Link>
                        </div>}
                    <div className="nav-item ms-3" style={{minWidth: 70}}>
                        <Link className="nav-link" to="/" data-bs-toggle="offcanvas" data-bs-target="#cartOffCanvasRight" onClick={onToggle}>CART {itemsTotal > 0 && <>({itemsTotal})</>}</Link>
                    </div>
                </div>
                <div className="collapse navbar-collapse" ref={navRef}>
                    <ul className="navbar-nav">
                        <Link className="nav-link" to="/" onClick={onToggle}><li className="nav-item">HOME</li></Link>
                        <Link className="nav-link" to="/compositions" onClick={onToggle}><li className="nav-item">PERMANENT COLLECTION</li></Link>
                        <Link className="nav-link" to="/drops" onClick={onToggle}><li className="nav-item">DROPS</li></Link>
                        <Link className="nav-link" to="https://alyssabreid.com/digital-portfolio" target="_blank" onClick={onToggle}><li className="nav-item">DIGITAL PORTFOLIO</li></Link>
                        <Link className="nav-link" to="/about" onClick={onToggle}><li className="nav-item">ABOUT</li></Link>
                        <Link className="nav-link" to="/contact" onClick={onToggle}><li className="nav-item">CONTACT</li></Link>
                    </ul>
                    {/* Visible on screens smaller than md */}
                    <ul className="navbar-nav d-md-none">
                        {/* TODO: bring portfolio into Galleried*/}
                        <Link className="nav-link" to='https://alyssabreid.com/digital-portfolio' target="_blank" onClick={onToggle}>
                            {/* <i className="bi bi-collection" /> */}
                            DIGITAL PORTFOLIO
                        </Link>
                        <Link className="nav-link" to="/" data-bs-toggle="offcanvas" data-bs-target="#cartOffCanvasRight" onClick={onToggle}>CART {itemsTotal > 0 && <>({itemsTotal})</>}</Link>
                        {!!user?.token 
                            //? <UserDropdown ref={dropdownRef} onToggle={onDropdownToggle} onLogout={handleLogout} />
                            ? <>
                                {(user?.role === Role.Admin || user?.role === Role.Super) && <Link className="nav-link" to="/admin" onClick={onToggle}>ADMIN</Link>}
                                <Link className="nav-link" to="/" onClick={handleLogout}>LOG OUT</Link>
                            </>
                            : <Link className="nav-link" to="/login" onClick={onToggle}>LOG IN</Link>}
                    </ul>
                </div>
            </div>
        </nav>);
}

const UserDropdown = React.forwardRef<HTMLDivElement, {onToggle: () => void, onLogout: () => void}>(({onToggle, onLogout}, ref) => {
    const { user } = useAppSelector(state => state.auth);
    const handleLogout = React.useCallback(() => {
        onLogout();
        onToggle();
    }, [onLogout, onToggle]);

    return(<div className="nav-item dropdown" ref={ref} onBlur={onToggle}>
        <button className="nav-link dropdown-toggle btn btn-link" onClick={onToggle}>{user?.first.toUpperCase()}</button>
        <ul className="dropdown-menu dropdown-menu-end position-absolute">
            {(user?.role === Role.Admin || user?.role === Role.Super) && <li><Link className="dropdown-item" to="/admin" onMouseDown={onToggle}>ADMIN</Link></li>}
            <li><Link className="dropdown-item" to="/" onMouseDown={handleLogout}>LOG OUT</Link></li>
        </ul>
    </div>);
});