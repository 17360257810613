import { Address } from "./Address";
import { Composition } from "./Composition";
import { User } from "./User";
import { ValidationResult } from "./ValidationResult";

export interface Client {
    id: string;
    name: string;
    brandName: string;
    first: string;
    last: string;
    address: Address;
    phone: string;
    users: User[];
    compositions: Composition[];
    isActive: boolean;
}

export function validateClient(client?: Partial<Client>) {
    const validationResult: ValidationResult<Client> = {
        valid: false,
        errors: {id: '', name: '', brandName: '', first: '', last: '', address: '', phone: '', users: '', compositions: '', isActive: ''}
    };

    if (!client || Object.entries(client).length === 0) {
        for (const key in validationResult.errors) {
            (validationResult.errors as any)[key] = validateClientField({key, value: undefined});
        }    
        return validationResult;
    }

    let key: keyof Client;
    for (key in client) {
        const value = client ? client[key] : undefined;
        validationResult.errors[key] = validateClientField({key, value});
    }    

    // no errors, validate
    if (Object.entries(validationResult.errors).every(e => !e[1])) {
        validationResult.valid = true;
    }

    return validationResult; 
}

export function validateClientField({key, value}: {[key: string]: string | boolean | Address | User[] | Composition[] | undefined}): string {
    let error = '';

    switch (key) {
        case 'name':
            if (!value) {
                error = 'Name is required';
            }
            break;
        case 'brandName':
            if (!value) {
                error = 'Brand is required';
            }
            break;
        case 'users':
        case 'compositions':        
        default:
            break;
    }
    if(error) console.log('Client validation error', error);
    return error;
}