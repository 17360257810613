import React from "react";
import { useAppDispatch, useAppSelector, useModal } from "../../hooks";
import { Spinner } from "../../components/Spinner";
import { Role, User } from "../../types/User";
import { Link, useNavigate } from "react-router-dom";
import { ConfirmRemoveFooter, Modal, UserConfirmRemoveBody } from "../../components/Modal";
import { toast } from "react-toastify";
import { userService } from "../../features/users/userService";

export const Users = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(true);
    const [users, setUsers] = React.useState<User[]>([]);
    const [selected, setSelected] = React.useState<User>({} as User);
    const { user } = useAppSelector(state => state.auth);
    const [modalRef, showModal, hideModal] = useModal();

    React.useEffect(() => {
        (async () => {
            const result = await userService.getAll(dispatch);
            if (result) {
                setUsers(result);
            } else {
                toast.error('Could not get users');
            }
            
        })();
        setLoading(false);
    }, [dispatch]);

    const handleRemove = React.useCallback((id: string) => {
        setSelected(users.find(o => o.id === id) as User);
        showModal();
    }, [users, showModal]);

    const handleConfirmRemove = React.useCallback(async () => {
        hideModal();
        setUsers(prev => prev.filter(o => o.id !== selected.id));
        const result = await userService.remove(selected.id!, dispatch);
        if (result) {
            toast.success(`User ${result.id} removed`);
        }
        setSelected({} as User);        
    }, [dispatch, hideModal, selected.id]);
    
    return(loading ? <Spinner /> : 
        <div className="m-3">
            <button className=" btn btn-primary float-end my-3" onClick={() => navigate("create")}><i className="bi-plus" /> New User</button>
            <table className="table table-sm">
                <thead>
                    <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Active</th>
                        {user?.role === Role.Super && <th className="text-center text-danger"><i className="bi bi-trash" /></th>}
                    </tr>
                </thead>
                <tbody>
                    {users?.length > 0 && users.map((u, i) => 
                        <tr key={u.id} className="align-middle">
                            <td><Link to={`${u.id}`}>{i + 1}</Link></td>
                            <td>{u.first} {u.last}</td>
                            <td>{u.email}</td>
                            <td>{u.role}</td>
                            <td>{u.isActive ? 'Active' : 'Inactive'}</td>
                            {user?.role === Role.Super && <td className="text-center">
                                <button className="btn btn-outline-danger btn-sm border-0" onClick={() => handleRemove(u.id!)}><i className="bi bi-x" /></button>
                            </td>}
                        </tr>)}
                </tbody>
            </table>    
            <Modal 
                id={selected.id!} 
                title={'Remove User'}
                ref={modalRef} 
                body={() => <UserConfirmRemoveBody id={selected.id!} first={selected.first} last={selected.last} role={selected.role} isActive={selected.isActive ? 'True' : 'False'} />}
                footer={ConfirmRemoveFooter({onConfirm: handleConfirmRemove, onHide: hideModal})}
                onConfirm={handleConfirmRemove} 
                onHide={hideModal} />
        </div>);
}